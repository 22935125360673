import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogTitle,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
  Divider, IconButton, InputBase, Paper,
  DialogActions
} from "@mui/material";
import "./trcuk.css";
import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
  DesktopDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import axios from "axios";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { useFormik } from "formik";
import swal from "sweetalert";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useOutletContext } from "react-router-dom";
import TooltipCom from "../../../Helpers/ToolTipComponent";
import BrLineItems from "../Forwarder/BrLineItems";
import DataTable, { TableColumn } from "react-data-table-component";
import { Endpoints } from "../../../Enviornment/Endpoints";
import WareHouseUpdate from "./WareHouseUpdate";
import GateInOutDetails from "./GateInOutDetails";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import WhLineItems from "./WhLineItems";
const customStyles = {
  headRow: {
    style: {
      border: 'none',
      backgroundColor: '#0b51b7',

    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: 'white'
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
      // cursor:'pointer'
    },
  },

};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function ShipmentSideWh() {
  const [load, setLoad, fromDate, toDate, setTd, setFd, mode] = useOutletContext<any>();
  const [showgrid, setShowGrid] = useState(true);
  const [open, setOpen] = useState(false);
  const [gateInOutDialog,setGateInOutDialog] = useState(false)
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [showData, setShowData] = useState<any>()
  const [role, setRole] = useState<any>(sessionStorage.getItem("role"));
  const [scheduleHistory, setScheduleHistory] = useState<any>([])
  const [openHistory, setOpenHistory] = useState(false)
  const [nodata, setNodata] = useState(false);
  const [wareHouseDialog,setWareHouseDialog] = useState<any>(false)
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {},
  };

  const filterData = rowData.filter((data: any) => {
    return Object.values(data).some((value) => {
      if (value === null || value === undefined || value === '') {
        return false;
      }
      return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    });
  });
  const columns: TableColumn<any>[] = [
    {
      name: "INVOICE #",
      sortable: true,
      selector: row => row.InvoiceNumber !== '' && row.InvoiceNumber !== null && row.InvoiceNumber !== undefined ? row.InvoiceNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
    },

    {
      name: "CONTAINER #",
      sortable: true,
      selector: row => row.ContainerNumber !== '' && row.ContainerNumber !== null && row.ContainerNumber !== undefined ? row.ContainerNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    // {
    //   name: "SCHEDULED DELIVERY DATE",
    //   selector: row => row.scheduledDate !== '' && row.scheduledDate !== null && row.scheduledDate !== undefined ? row.scheduledDate : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    //   width: '250px',
    // },
    {
      name: "SCHEDULED DELIVERY DATE",
      // selector: row => row.scheduledDate !== '' && row.scheduledDate !== null && row.scheduledDate !== undefined ? row.scheduledDate : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '250px',
      cell: (row) => <a
        style={{
          width: "100%",
          lineHeight: 0.5,
          color: "blue",
        }}
        className="btn btn-info"
        onClick={() => deliveryScheduleHistory(row)}
      >
        {row.scheduledDate}
      </a>
    },
    {
      name: 'GATE OUT',
      cell: (row: any) => <EditOutlinedIcon sx={{cursor:'pointer',color:'#1976d2'}} onClick={() => handleUpdateGateOutDetails(row)}/>,
      width: '200px',
      omit: sessionStorage.getItem('email') == 'wh001@gmail.com' ||  sessionStorage.getItem('email') == 'wh002@gmail.com' || sessionStorage.getItem('email') == 'wh003@gmail.com' ? false : true,
    },
    {
      name: "GATE IN DATE",
      sortable: true,
      // selector: row => row.gateInOrOutDetails !== '' && row.gateInOrOutDetails !== null && row.gateInOrOutDetails !== undefined ? row.gateInOrOutDetails.gateInDate : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
      cell: (row) => <a
        style={{
          width: "100%",
          lineHeight: 0.5,
          color: "blue",
        }}
        className="btn btn-info"
        onClick={() => gateInDetails(row)}
      >
        {row.gateInOrOutDetails.gateInDate}
      </a>
    },

    {
      name: "WAREHOUSE",
      sortable: true,
      selector: row => row.WhId !== '' && row.WhId !== null && row.WhId !== undefined ? row.WhId : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
      omit: sessionStorage.getItem('email') == 'wh001@gmail.com' ||  sessionStorage.getItem('email') == 'wh002@gmail.com' || sessionStorage.getItem('email') == 'wh003@gmail.com' ? true : false,
   
    },
    // {
    //   name: "WAREHOUSE NAME",
    //   sortable: true,
    //   selector: row => row.WhName != "" && row.WhName != null && row.WhName != undefined ? row.WhName : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    //   omit: sessionStorage.getItem('email') == 'wh001@gmail.com' ||  sessionStorage.getItem('email') == 'wh002@gmail.com' || sessionStorage.getItem('email') == 'wh003@gmail.com' ? true : false,
   
    //   width: '250px',
    // },
    // {
    //   name: "WAREHOUSE BOOKING REFERANCE #",
    //   sortable: true,
    //   selector: row => row.WhBookingRefNo !== '' && row.WhBookingRefNo !== null && row.WhBookingRefNo !== undefined ? row.WhBookingRefNo : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    //   width: '350px',
    //   omit: mode == 'Air' ? true : false,
    // },
    {
      name: "WAREHOUSE BOOKING REFERENCE #",
      sortable: true,
      // selector: row => row.InvoiceNumber !== '' && row.InvoiceNumber !== null && row.InvoiceNumber !== undefined ? row.InvoiceNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '250px',
      cell: (row) => <a
        style={{
          width: "100%",
          lineHeight: 0.5,
          color: "blue",
        }}
        className="btn btn-info"
        onClick={() => bookingDetails(row)}
      >
        {row.WhBookingRefNo}
      </a>
    },
    {
      name: "SHIPPER",
      sortable: true,
      selector: row => row.shipper !== '' && row.shipper !== null && row.shipper !== undefined ? row.shipper : <span style={{ fontStyle: 'italic' }}>- NA -</span>,

      width: '200px',

      style: mode != 'Ocean' ? {

        cursor: 'pointer',
        textDecaration: 'underline'
      } : null

    },
    {
      name: "CONSIGNEE",
      sortable: true,
      selector: row => row.Consignee !== '' && row.Consignee !== null && row.Consignee !== undefined ? row.Consignee : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Air' ? true : false,
      width: '150px',
    },
    {
      name: "ITEM NAME",
      sortable: true,
      selector: row => row.itemName !== '' && row.itemName !== null && row.itemName !== undefined ? row.itemName : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Air' ? true : false,
      width: '200px',
    },
    {
      name: "QTY",
      sortable: true,

      selector: row => row.itemQty !== '' && row.itemQty !== null && row.itemQty !== undefined ? row.itemQty : <span style={{ fontStyle: 'italic' }}>- NA -</span>,

      width: '200px',
    },
    {
      name: "HZ GOODS",
      selector: row => row.hzGoods !== '' && row.hzGoods !== null && row.hzGoods !== undefined ? row.hzGoods : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    },
    
  ]
  const columns1: TableColumn<any>[] = [
    {
      name: 'SCHEDULE DATE',
      selector: row => row.scheduledDate || <span style={{ fontStyle: 'italic' }}>- NA -</span>
    },
    {
      name: 'UPDATED BY',
      selector: row => row.updatedBy || <span style={{ fontStyle: 'italic' }}>- NA -</span>
    },
    {
      name: 'REMARKS',
      selector: row => row.remarks || <span style={{ fontStyle: 'italic' }}>- NA -</span>
    }

  ]
  const deliveryScheduleHistory = (row: any) => {
    console.log(row, "row data")
    let requestPayload = {
      docReference: row.docReference
    }
    config.params = requestPayload
    axios.get(Endpoints.delivery_Schedule_History, config).then((res: any) => {
      console.log(res.data, "history report")
      if (res.data.primary[0].deliveryScheduleDetails.length > 0) {
        setScheduleHistory(res.data.primary[0].deliveryScheduleDetails)
        setShowData(row)
        setOpenHistory(true)
      }
    })
  }
  const bookingDetails = (data: any) => {
    setShowData(data)
    setWareHouseDialog(true)
   }
  const gateInDetails = (data:any) =>{
      setGateInOutDialog(true)
      setShowData(data)
  }
  const handleUpdateGateOutDetails = (data: any) => {
    setOpen(true)
    setShowData(data)
  }
  useEffect(() => {
    setLoad(true);
    shipmentsatwh();
  }, [fromDate, toDate, mode]);

  const shipmentsatwh = () => {
    setShowGrid(true);
    let requestPayload = {
      shipmentStatus: 'whGateIn'
    }
    config.params = requestPayload;
    let url = sessionStorage.getItem('email') === 'wh001@gmail.com' || sessionStorage.getItem('email') === 'wh002@gmail.com' || sessionStorage.getItem('email') === 'wh003@gmail.com' ? Endpoints.wh_Inward_Truck_List : Endpoints.wh_In_Outward_List 
    
    axios.get(url, config).then((res) => {
      if (res.data.primary.length === 0) {
        setShowGrid(false);
        setLoad(false);
      }else{
        setLoad(false);
        setRowData(res?.data?.primary);
        console.log(res.data.primary);
      }
      
    });
  };
  const handleClose = () => {
    setOpen(false)
    setGateInOutDialog(false)
    setWareHouseDialog(false)
    setOpenHistory(false)
  }
  const handleCallback = () =>{
    shipmentsatwh()
  }
  const handleClear = () => {
    setSearchTerm('')
  }
  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    console.log(event.target.value, "target value")
  };
  const handlemap = () => {
    setNodata(false);
    setOpenHistory(false)
  };
  return (
    <div className={classes.root}>
      {rowData.length > 0 && showgrid &&
        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'end', width: '100%' }}>
          <Paper
            component="form"
            elevation={0}
            className="custom-search"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', float: 'right', width: 400 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={sessionStorage.getItem('email') === 'wh001@gmail.com' || sessionStorage.getItem('email') === 'wh002@gmail.com' || sessionStorage.getItem('email') === 'wh003@gmail.com'? 'Search' :'Search By WareHouse'}
              
              inputProps={{ 'aria-label': 'search google maps' }}
              size="small"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" size="small" onClick={handleClear}>
              {searchTerm === '' ? <SearchIcon /> : <CloseIcon />}
            </IconButton>
          </Paper>
        </Box>}
      <Box sx={{ p: 0, mt: 1 }}>
        {showgrid ? (
          <DataTable
            style={{ borderRadius: 3, cursor: 'pointer' }}
            columns={columns}
            data={filterData}
            responsive={true}
            customStyles={customStyles}
            theme="default"

            pagination
            dense
            striped
            highlightOnHover={true}
          // onRowClicked={(data) => handleData(data)}

          />
        ) : (
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            No data found
          </Alert>
        )}
      </Box>
      <div className="dialog">
        {open ? (
          <WareHouseUpdate
            onOpen={open}
            onClose={() => handleClose()}
            data={showData}
            shipmentStatus="whGateOut"
            parentCallback={handleCallback}
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {gateInOutDialog ? (
          <GateInOutDetails
            onOpen={gateInOutDialog}
            onClose={() => handleClose()}
            data={showData}
            event="gateIn"
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div className="dialog">
        {wareHouseDialog ? (
          <WhLineItems
            onOpen={wareHouseDialog}
            onClose={() => handleClose()}
            data={showData}
            event="gateIn"
            sx={{
              "& .MuiDialog-root": {
                borderRadius: "100px",
                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                zIndex: "9999 !important",
              },
            }}
          />
        ) : null}
      </div>
      <div>
        <Dialog open={openHistory} onClose={handlemap} maxWidth={'md'} fullWidth={true}>
          <>
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                borderRadius: "20px",

              }}
            >
              <Typography variant="h6">Scheduled Delivery History</Typography>
              <Typography >Container #: &nbsp;&nbsp; {showData?.containerNumber || showData?.ContainerNumber}</Typography>
            </Box>
            <Box sx={{ px: 2 }}>
              <DataTable
                style={{ borderRadius: 3, cursor: 'pointer' }}
                columns={columns1}
                data={scheduleHistory}
                responsive={true}
                customStyles={customStyles}
                theme="default"
                pagination
                dense
                striped
                highlightOnHover={true}
              // onRowClicked={(data) => handleData(data)}

              />
            </Box>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </>
        </Dialog>
      </div>
    </div>
  );
}

export default ShipmentSideWh;
