import { createBrowserRouter, createHashRouter } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import AcceptedByCarrier from "../components/pages/Carrier/AcceptedByCarrier";
import CarrierAck from "../components/pages/Carrier/CarrierPendingAck";
import IntransitEventDisplay from "../components/pages/Carrier/IntransitEventDisplay";
import RejectedByCarrier from "../components/pages/Carrier/RejectedByCarrier";
import AcceptedByForwarder from "../components/pages/Forwarder/AcceptedBR";
import PendingBookingRequest from "../components/pages/Forwarder/PendingForwarderConfirmation";
import RequestedByShipments from "../components/pages/Forwarder/RejectedBR";
// import BookingRequest from "../components/pages/Forwarder/BrAck";
import PoAccept from "../components/pages/Purchase/PoAccept";
import PoForAcknowledgement from "../components/pages/Purchase/POForAcknowledgement";
import RejectedPo from "../components/pages/Purchase/RejectedPo";
import BrFileupload from "../components/pages/UploadFiles/BRupload";
import CarrierConfirmation from "../components/pages/UploadFiles/CarrierConfirmation";
import CrFileupload from "../components/pages/UploadFiles/CarrierUpload";
import CommonFileUploader from "../components/pages/UploadFiles/CommonFileUploader";
import InTransitEventFileupload from "../components/pages/UploadFiles/intransitEvent";
import ReturnFileUpload from "../components/pages/UploadFiles/ReturnsUpload";
import PendingWhRequests from "../components/pages/WareHouse/PendingWhRequests";
import Fileupload from "../Helpers/Fileupload";
import Login from "../Layout/Login";
import PrivateRoutes from "../Utilities/PrivateRoutes";
import AccptedWHShipments from "../components/pages/WareHouse/AcceptedWhshipments";
import WhRequest from "../components/pages/UploadFiles/WhRequest";
import WhConfirmation from "../components/pages/UploadFiles/whConfirmation";

import OutWardShipments from "../components/pages/WareHouse/OutWardShipments";
import ShipmentsCha from "../components/pages/CHA/shipments";
import CustomClearence from "../components/pages/CHA/customclearencescreen";
import GateOutShipments from "../components/pages/CHA/GateOutShipments";
import AddLegs from "../components/pages/Carrier/AddLegInfo";
import SpaceUtilizatoinReport from "../components/pages/WareHouse/SpaceUtilizationReport";

import InWardTruckDetails from "../components/pages/WareHouse/InwardTruckDetails";
import RejectedShipments from "../components/pages/WareHouse/RejectedShipments";
import IntransitForDelivery from "../components/pages/Carrier/IntransitForDelivery";
import DeliveredInTransitShipments from "../components/pages/Carrier/DeliveredIntransitShipments";
import AcceptedReturns from "../components/pages/Returns/AcceptedReturns";
import AckRejected from "../components/pages/Returns/ReturnsForAck";
import RejectedReturns from "../components/pages/Returns/RejectedReturns";
import ManageDocs from "../Helpers/ManageDocs";
import ShipmentSideWh from "../components/pages/WareHouse/ShipmentSideWh";
import ExportedCustomClearence from "../components/pages/CHA/ExportedCustoms";
import ImportAndGateOut from "../components/pages/CHA/Importandgateout";
import SearatesInfo from "../components/pages/SeaRates/SearatesInfo";
import PartnerOnboarding from "../components/pages/Registrations/PartnerOnboarding";
import ChangePassword from "../Layout/ChangePassword";
import ShipmentRegistrations from "../components/pages/Registrations/ShipmentRegistrations";
import OtpVerification from "../Layout/OtpVerification";
import DigitalThread from "../components/pages/Registrations/DigitalThread";
import RegistredShipments from "../components/pages/Registrations/RegistredShipments";
import BookingRequest from "../components/pages/Forwarder/BookingRequest";
import CarrierBookingRequest from "../components/pages/Carrier/CarrierBookingRequest";
import ForwarderConfirmationUpload from "../components/pages/UploadFiles/ForwarderConfirmationUpload";
import DeliveredReport from "../components/pages/Carrier/DeliveredReport";
import ForgetPassword from "../Layout/ForgetPassword";
import SignUp from "../Layout/SignUp";
import PasswordverifyMsg from "../Layout/PasswordverifyMsg";
import CarrierWiseList from "../components/pages/Registrations/CarrierWiseList";
import ShipmentBulkUpload from "../components/pages/Registrations/ShipmentBulkUpload";
import ShipmentRegistrationPreview from "../components/pages/Registrations/ShipmentRegistrationPreview";
import DutyFreeRetailOnboarding from "../components/pages/DutyFreeRetailOnboarding/DutyFreeRetailOnboarding";
const router = createHashRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/changePassword",
    element: <ChangePassword />
  },
  {
    path: "/forgotPassword",
    element: <ForgetPassword />
  },
  {
    path: '/otpVerification',
    element: <OtpVerification />
  },
  {
    path: '/registered',
    element: <PasswordverifyMsg />
  },

  {
    path: "/",
    element: <PrivateRoutes />,
    children: [
      {
        path: 'sea_Rates',
        element: <SearatesInfo />
      },
      {
        path: 'registrations',
        element: <PartnerOnboarding />
      },
      {
        path: 'shipmentRegistrations',
        element: <ShipmentRegistrations />
      },
      {
        path: 'registred_Shipments',
        element: <RegistredShipments />
      },
      {
        path: 'digitalThread',
        element: <DigitalThread />
      },
      {
        path: "/",
        element: <Dashboard />,
      },

      {
        path: "pending_for_approval",
        element: <PoForAcknowledgement />,
      },
      {
        path: "pending_purchase_order",
        element: <PoForAcknowledgement />,
      },
      {
        path: "accepted_purchase_order",
        element: <PoAccept />,
      },
      {
        path: "rejected_purchase_order",
        element: <RejectedPo />,
      },
      {
        path: "/bookingrequestack",
        element: <PendingBookingRequest />,
      },
      {
        path: '/supported_carrier_list',
        element: <CarrierWiseList />
      },
      {
        path: '/shipment_bulk_upload',
        element: <ShipmentBulkUpload />
      },
      {
        path: '/shipment_preview',
        element: <ShipmentRegistrationPreview />
      },
      {
        path: "/confirmed_by_forwarder",
        element: <AcceptedByForwarder />,
      },
      {
        path: "/rejected_by_forwarder",
        element: <RequestedByShipments />,
      },
      {
        path: "/pending_carrier_confirmation",
        element: <CarrierAck />,
      },
      {
        path: "/carrier_confirmation",
        element: <CarrierAck />,
      },
      {
        path: "/booking_request",
        element: <BookingRequest />,
      },
      {
        path: "/upload_booking_request",
        element: <BrFileupload />,
      },
      {
        path: "/pending_forwarder_confirmation",
        element: <PendingBookingRequest />,
      },
      {
        path: "/confirmed_by_carrier",
        element: <AcceptedByCarrier />,
      },
      {
        path: "/rejected_by_carrier",
        element: <RejectedByCarrier />,
      },
      {
        path: "/upload_forwarder_confirmation",
        element: <ForwarderConfirmationUpload />,
      },
      {
        path: "/carrier_booking_request",
        element: <CarrierBookingRequest />,
      },
      {
        path: "/upload_documents",
        element: <CommonFileUploader />,
      },
      {
        path: "/carrier_confirmation_upload",
        element: <CarrierConfirmation />,
      },
      {
        path: "/intransit_events_upload",
        element: <InTransitEventFileupload />,
      },
      {
        path: "/intransit_shipments",
        element: <IntransitEventDisplay />,
      },
      {
        path: "/upload_purchase_order",
        element: <Fileupload />,
      },

      // {
      //   path: "/pending_for_approval",
      //   element: <PoForAcknowledgement />,
      // },
      {
        path: "/upload_return_order",
        element: <ReturnFileUpload />,
      },
      {
        path: "/pending_requests",
        element: <PendingWhRequests />,
      },
      {
        path: "/confirmed_shipments",
        element: <AccptedWHShipments />,
      },
      {
        path: "/wareHouse_request_upload",
        element: <WhRequest />,
      },
      {
        path: "/wareHouse_confirmation_upload",
        element: <WhConfirmation />,
      },
      {
        path: "/inward_truckdetails",
        // element: <InwardForm />,
        element: <InWardTruckDetails />,
      },
      {
        path: "/rejected_shipments",
        element: <RejectedShipments />,
      },
      {
        path: "/outward_shipments",
        element: <OutWardShipments />,
      },
      {
        path: "/space_utilization_report",
        element: <SpaceUtilizatoinReport />,
      },
      {
        path: "/pending_custom_clearance",
        element: <ShipmentsCha />,
      },
      // {
      //   path: "/shipments_for_custom_clearance",
      //   element: <ShipmentsCha />,
      // },
      {
        path: "/import_custom_clearence",
        element: <CustomClearence />,
      },
      {
        path: "/export_custom_clearence",
        element: <ExportedCustomClearence />,
      },
      {
        path: "/gateOut_shipments",
        element: <ImportAndGateOut />,
      },
      {
        path: "/pending_forwarder_confirmation",
        element: <PendingBookingRequest />,
      },
      {
        path: "/add_legs",
        element: <AddLegs />,
      },
      {
        path: "/confirm_goods_reciept",
        element: <IntransitForDelivery />,
      },
      {
        path: "/delivered_report",
        element: <DeliveredReport />,
      },
      {
        path: "/return_orders_for_acknowledgement",
        element: <AckRejected />,
      },
      {
        path: "/confirmed_return_orders",
        element: <AcceptedReturns />,
      },
      {
        path: "/rejected_return_orders",
        element: <RejectedReturns />,
      },
      {
        path: "/manage_documents",
        element: <ManageDocs />,
      },
      {
        path: "/shipments_at_warehouse",
        element: <ShipmentSideWh />,
      },

      {
        path: '/duty_free_retail_onboard',
        element: <DutyFreeRetailOnboarding />
      }
    ],
  },

]);

export default router;
