
import React, { useEffect } from 'react'
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Tab, Tooltip } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import IntransitForDelivery from './IntransitForDelivery';
import DeliveredInTransitShipments from './DeliveredIntransitShipments';
import { useOutletContext } from 'react-router-dom';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(0),
        fontSize: "10px",
        // fontWeight: "bold",
        minHeight: "100%",
        "& .MuiDialog-root": {
            borderRadius: "100px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
            zIndex: "9999 !important",
        },
    },
    maincontent: {},
}));
export default function DeliveredReport() {
    const classes = useStyles();
    const [value, setValue] = React.useState("pending");
    const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    useEffect(() => {
        // console.log(mode,"shipment mode")
    }, [mode])
    return (
        <div className={classes.root}>
            <Paper elevation={0}>
                <Box>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                                onChange={handleTabChange}
                                aria-label="lab API tabs example"
                            >
                                {sessionStorage.getItem('role') != 'consignee' ? <Tab label="Out For Delivery" value="pending" /> : <Tab label="Confirm Goods Receipt Note" value="pending" />}
                                <Tab label="Delivered Shipments" value="delivered" />
                            </TabList>
                        </Box>
                        <TabPanel value="pending" sx={{ padding: '10px 0px' }}>
                            <IntransitForDelivery shipmentType={mode} />
                        </TabPanel>
                        <TabPanel value="delivered" sx={{ padding: '10px 0px' }}>
                            <DeliveredInTransitShipments shipmentType={mode} />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Paper>
        </div>
    )
}
