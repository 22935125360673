
import axios from "axios";
import { useCallback, useEffect, useMemo, useState, useRef, useDeferredValue } from "react";
import { Box, Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CrLineItems from "./CarrierLineItems";
import LegInfoVisual from "./carrierLeginfoVisual";
import { useNavigate, useOutletContext } from "react-router-dom";
import IntransitEventsMapping from "../../../Helpers/IntransitEventsMapping";
import "../../../Styles.css";
import swal from "sweetalert";
import moment from "moment";
import React from "react";
import { Endpoints } from "../../../Enviornment/Endpoints";
import DataTable, { TableColumn } from 'react-data-table-component';
import SearchIcon from '@mui/icons-material/Search';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { config } from "process";
import { useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const customStyles = {
  headRow: {
    style: {
      border: 'none',
      backgroundColor: '#0b51b7',

    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: 'white'
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
      // cursor:'pointer'
    },
  },

};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 15,
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));


function IntransitEventDisplay() {
  const nav = useNavigate();
  const [showgrid, setShowGrid] = useState(false);
  const [nodata, setNodata] = useState(false);
  const [EventData, setEventData] = useState<any>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [legOpen, setLegOpen] = useState<boolean>(false);
  const [legData, setLegData] = useState<any>([]);
  const [transitData, setTransitData] = useState<any>([]);
  const [openLineItems, setOpenLineItems] = useState(false);
  const [lineData, setLineData] = useState([]);
  const [hscode, setHscode] = useState("");
  const [itemname, setItemName] = useState("");
  const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
  // const [selectedValue, setSelectedValue] = React.useState(mode);
  const [filterText, setFilterText] = React.useState('');
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const gridRef = useRef<any>();
  const classes = useStyles();
  const [rowData, setRowData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>('');
  const navigate = useNavigate();
  const [showData, setShowData] = useState<any>()
  const [scheduleHistory, setScheduleHistory] = useState<any>([])
  const [openHistory, setOpenHistory] = useState(false)
  const [date, setDate] = useState(null);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const { register, resetField, reset, setValue, formState: { errors }, control, handleSubmit, watch } = useForm({
    defaultValues: {
      date: '',
      remarks: '',
    },
  });
  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    console.log(event.target.value, "target value")
  };
  const handleClear = () => {
    setSearchTerm('')
  }
  const filterData = rowData.filter((data: any) => {
    return Object.values(data).some((value) => {
      if (value === null || value === undefined || value === '') {
        // Include null and undefined values
        return false;
      }
      return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
    });
  });
  console.log("Filtered DATA..................", filterData)
  const columns: TableColumn<any>[] = [
    // {
    //   name: "EVENTS AVAILABILITY",
    //   sortable: true,
    //   selector: row => row.lastEvent !== '' && row.lastEvent !== null && row.lastEvent !== undefined ? <span style={{ color: '#5fb25c', cursor: 'pointer', }}>YES</span> : <span style={{ color: '#ad2f33', cursor: 'pointer', }}>NO</span>,
    //   width: '200px'

    // },

    {
      name: 'EVENTS AVAILABILITY',
      sortable: true,
      cell: (row) => <a
        style={{
          width: "100%",
          lineHeight: 0.5,
          color: "blue",
        }}
        className="btn btn-info"
        onClick={() => handleData(row)}
      >
        {row.lastEvent !== '' && row.lastEvent !== null && row.lastEvent !== undefined ? <span style={{ color: '#5fb25c', cursor: 'pointer', }}>YES</span> : <span style={{ color: '#ad2f33', cursor: 'pointer', }}>NO</span>}
      </a>
      //  <Button size="small" variant="contained" sx={{ backgroundColor: '#5fb25c' }} onClick={() => handleSubmitConfirmReceipt(row)}> </Button>,
      // width: '200px'
    },
    {
      name: "LAST EVENT",
      sortable: true,
      width: '250px',
      selector: row => row.lastEvent !== '' && row.lastEvent !== null && row.lastEvent != undefined ? (
        // <span>
        //   {row.lastEvent}
        // </span>
        <Tooltip title={row.lastEvent} arrow><span>{row.lastEvent}</span></Tooltip>
      ) : (
        <span style={{ fontStyle: 'italic' }}>

          - NA -
        </span>
      ),

    },
    {
      name: "ETA",
      selector: row => row.eta !== '' && row.eta !== null && row.eta !== undefined ? <Tooltip title={row.eta} arrow><span>{row.eta}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
    },
    {
      name: "CONTAINER #",
      sortable: true,
      selector: row => row.containerNumber !== '' && row.containerNumber !== null && row.containerNumber != undefined && row.containerNumber !== 'NA' ? <Tooltip title={row.containerNumber} arrow><span>{row.containerNumber}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      // selector: row => row.containerNumber !== '' && row.containerNumber !== null && row.containerNumber !== undefined ? row.containerNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Air' ? true : false,
      width: '200px',
    },
  
    {
      name: "SCHEDULED DELIVERY DATE",

      width: '250px',
      cell: (row) => <a
        style={{
          width: "100%",
          lineHeight: 0.5,
          color: "blue",
        }}
        className="btn btn-info"
        onClick={() => deliveryScheduleHistory(row)}
      >
        {row.scheduledDate}
      </a>
    },
    {
      name: 'EDIT SCHEDULE',
      cell: (row: any) => <EditOutlinedIcon onClick={() => handleUpdateSchedule(row)} sx={{cursor:'pointer',color:'#1976d2'}} />,
      width: '200px',
      omit: sessionStorage.getItem('email') == 'wh001@gmail.com' ||  sessionStorage.getItem('email') == 'wh002@gmail.com' || sessionStorage.getItem('email') == 'wh003@gmail.com' ? true : false,
    },
    {
      name: "INVOICE #",
      sortable: true,
      // selector: row => row.invoiceNumber !== '' && row.invoiceNumber !== null && row.invoiceNumber !== undefined ? row.invoiceNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      selector: row => row.invoiceNumber !== '' && row.invoiceNumber !== null && row.invoiceNumber != undefined && row.invoiceNumber !== 'NA' ? <Tooltip title={row.invoiceNumber} arrow><span>{row.invoiceNumber}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "HBL #",
      sortable: true,
      // selector: row => row.hblNumber != "" && row.hblNumber != null  && row.hblNumber != undefined ? row.hblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      selector: row => row.hblNumber !== '' && row.hblNumber !== null && row.hblNumber != undefined && row.hblNumber !== 'NA' ? <Tooltip title={row.hblNumber} arrow><span>{row.hblNumber}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,

      omit: mode == 'Air' ? true : false,
      width: '150px',
    },
    {
      name: "BOOKING #",
      sortable: true,
      // selector: row => row.carrierReference !== '' && row.carrierReference !== null && row.carrierReference !== undefined ? row.carrierReference : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      selector: row => row.carrierReference !== '' && row.carrierReference !== null && row.carrierReference != undefined && row.carrierReference !== 'NA' ? <Tooltip title={row.carrierReference} arrow><span>{row.carrierReference}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,

      width: '150px',
      omit: mode == 'Air' ? true : false,
    },
    
    {
      name: "MAWB #",
      sortable: true,
      selector: row => row.awbNumber !== '' && row.awbNumber !== null && row.awbNumber !== undefined ? row.awbNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Ocean' ? true : false,
      width: '200px',

      style: mode != 'Ocean' ? {

        cursor: 'pointer',
        textDecaration: 'underline'
      } : null

    },
    {
      name: "MBL #",
      sortable: true,
      selector: row => row.mblNumber !== '' && row.mblNumber !== null && row.mblNumber != undefined && row.mblNumber !== 'NA' ? <Tooltip title={row.mblNumber} arrow><span>{row.mblNumber}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      // selector: row => row.mblNumber !== '' && row.mblNumber !== null && row.mblNumber !== undefined ? row.mblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Air' ? true : false,
      width: '150px',
    },
   
    {
      name: "HAWB #",
      sortable: true,
      selector: row => row.hawbNumber !== '' && row.hawbNumber !== null && row.hawbNumber !== undefined ? row.hawbNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      omit: mode == 'Ocean' ? true : false,
      width: '200px',
    },
    {
      name: "WAREHOUSE",
      sortable:true,
      selector: row => row.whId !== '' && row.whId !== null && row.whId != undefined && row.whId !== 'NA' ? <Tooltip title={row.whId} arrow><span>{row.whId}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      
      // selector: row => row.carrierName !== '' && row.carrierName !== null && row.carrierName !== undefined ? <Tooltip title={row.carrierName} arrow><span>{row.carrierName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '150px',
      omit: sessionStorage.getItem('email') == 'wh001@gmail.com' ||  sessionStorage.getItem('email') == 'wh002@gmail.com' || sessionStorage.getItem('email') == 'wh003@gmail.com' ? true : false,
    },
    {
      name: "CARRIER",
      selector: row => row.carrierName !== '' && row.carrierName !== null && row.carrierName !== undefined ? <Tooltip title={row.carrierName} arrow><span>{row.carrierName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '150px',
    },

    {
      name: "SHIPPER",
      width: '150px',
      selector: row => row.shipperName !== '' && row.shipperName !== null && row.shipperName !== undefined ? <Tooltip title={row.shipperName} arrow><span>{row.shipperName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    },
    {
      name: "CONSIGNEE",
      selector: row => row.consigneeName !== '' && row.consigneeName !== null && row.consigneeName !== undefined ? <Tooltip title={row.consigneeName} arrow><span>{row.consigneeName}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      // wrap: false,
      width: '170px',
    },
    {
      name: "CHANGE IN ETA",
      sortable: true,
      selector: row => row.changeInEta !== '' && row.changeInEta !== null && row.changeInEta != undefined && row.changeInEta !== 'NA' ? <Tooltip title={row.changeInEta} arrow><span>{row.changeInEta === row.eta ? <span style={{ fontStyle: 'italic' }}>- NA -</span> : row.changeInEta}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "INCO TERMS",
      selector: row => row.incoTerms !== '' && row.incoTerms !== null && row.incoTerms !== undefined ? row.incoTerms : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '150px'
    },
    {
      name: "PICKUP DATE",
      selector: row => row.pickUpDate !== '' && row.pickUpDate !== null && row.pickUpDate !== undefined && row.pickUpDate !== 'NA' ? row.pickUpDate : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '250px'
    },
    {
      name: "LAST EVENT UPDATED AT",
      sortable: true,
      selector: row => row.lastEventUpdatedAt !== '' && row.lastEventUpdatedAt !== null && row.lastEventUpdatedAt !== undefined ? row.lastEventUpdatedAt : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '250px'

    },
    
  ]
  const columns1: TableColumn<any>[] = [
    {
      name: 'SCHEDULE DATE',
      selector: row => row.scheduledDate || <span style={{ fontStyle: 'italic' }}>- NA -</span>
    },
    {
      name: 'UPDATED BY',
      selector: row => row.updatedBy || <span style={{ fontStyle: 'italic' }}>- NA -</span>
    },
    {
      name: 'REMARKS',
      selector: row => row.remarks || <span style={{ fontStyle: 'italic' }}>- NA -</span>
    }

  ]
  let requestPayload: any = {
    type: role,
    fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
    toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
    shipmentType: mode
  }
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  const filteredItems = rowData.filter(
    (item: any) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  );

  const handleData = (params: any) => {
    console.log("Event Screen Console....", params);
    setTransitData(params);
    setLineData(params);
    setOpenDetail(true);
    setEventData(params)
  };
  const deliveryScheduleHistory = (row: any) => {
    console.log(row, "row data")
    let requestPayload = {
      docReference: row.docReference
    }
    Api_Config.params = requestPayload
    axios.get(Endpoints.delivery_Schedule_History, Api_Config).then((res: any) => {
      console.log(res.data, "history report")
      if (res.data.primary[0].deliveryScheduleDetails.length > 0) {
        setScheduleHistory(res.data.primary[0].deliveryScheduleDetails)
        setShowData(row)
        setOpenHistory(true)
      }
    })
  }
  const handlemap = () => {
    setNodata(false);
  };
  const handleSubmitConfirmReceipt = (data: any) => {
    console.log(data, "dattatataa")
  }
  const handleUpdateSchedule = (data: any) => {
    setOpenHistory(true)
    setLegData(data)
    setShowData(data)
    console.log(data, "dataaa")
    const etaDates = data?.eta ? new Date(data.eta) : null;
    console.log(etaDates,"eta dates")
    const changeInEtaDates = data?.changeInEta ? new Date(data?.changeInEta) : null;
    const validDatesArray = [etaDates, changeInEtaDates].filter(date => date !== null);
    let maxDate;
    if (validDatesArray.length > 0) {
      maxDate = new Date(Math.max(...validDatesArray.map(date => date!.getTime())));
    } else {
      // If no valid dates are present, set minDate to null or some default value
      maxDate = null;
    }
    console.log(maxDate,"mindate");
    setMinDate(maxDate)
    setDate(data.scheduledDate)
    
    // return minimumDate;
  }
  const handleClose = () => {
    setOpenHistory(false)

    setScheduleHistory([])
  }
  const getIntransitList = () => {
    let array1: any;
    let array2: any;
    let array3: any;
    Api_Config.params = requestPayload
    setLoad(true);
    //Intransit_Header_List
    //intransit_List
    let url = sessionStorage.getItem('email') === 'wh001@gmail.com' || sessionStorage.getItem('email') === 'wh002@gmail.com' || sessionStorage.getItem('email') === 'wh003@gmail.com' ? Endpoints.wh_Intransit_List : Endpoints.intransit_List
    axios.get(url, Api_Config).then(async (res) => {
      setLoad(false)

      if (res.data.status.code === 'FAILURE') {
        console.log("...................", res.data.status.message)
        swal('Warning', res.data.status.message, 'warning')
      } else {
        array1 = res.data.primary
        // console.log(res.data.primary);d
        if (res.data.primary.length > 0) {
          console.log(array1)
          setShowGrid(true)
        }
      }
      setRowData(res.data.primary)

    }).catch((error) => {
      setShowGrid(false)
      setLoad(false)
    })
  }
  const handleLine = (params: any) => {
    setLineData(params.data);
    setOpenLineItems(true);
    setHscode(params.data.hscode);
    setItemName(params.data.itemName);
  };
  const shipmentModeHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    requestPayload.shipmentType = event.target.value
    // setSelectedValue(event.target.value);
    sessionStorage.setItem('shipmentType', event.target.value)
    setRowData([])
    getIntransitList()

  };
  const createData = (data: any) => {
    console.log(data, "data")
    let requestPayload = {
      docReference: showData.docReference
    }
    Api_Config.params = requestPayload
    axios.post(Endpoints.update_Delivery_Schedule, data, Api_Config).then((res: any) => {
      console.log(res.data, "after update")
      setOpenHistory(false)
      getIntransitList();
      reset();
    })
  }
  const handleChangeDate = (newDate: any) => {
    let data = newDate.$d;
    var responseDate = moment(data).format("YYYY-MM-DD");
    setDate(newDate);
    setValue(`date`, responseDate)

  };
 
  useEffect(() => {
    console.log(mode, "modeee")
    getIntransitList();
  }, [fromDate, toDate, mode]);
  return (
    <div className={classes.root}>
      {rowData.length > 0 && showgrid &&
        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between', width: '100%' }}>
          <Button variant="contained" onClick={() => navigate('/shipmentRegistrations')}>Add Shipments</Button>
          <Paper
            component="form"
            elevation={0}
            className="custom-search"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', float: 'right', width: 400 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder={mode === 'Ocean' ? "Search By HBL/MBL/Container/Invoice" : 'Search By HAWB/MAWB/Invoice'}
              inputProps={{ 'aria-label': 'search google maps' }}
              size="small"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" size="small" onClick={handleClear}>
              {searchTerm === '' ? <SearchIcon /> : <CloseIcon />}
            </IconButton>
          </Paper>
        </Box>}

      <Paper elevation={3} sx={{ borderRadius: 3 }}>
        {rowData.length > 0 && showgrid ? (
          <Box sx={{ p: 0, mt: 1 }}>
            <DataTable
              style={{ borderRadius: 3, cursor: 'pointer' }}
              columns={columns}
              data={filterData}
              responsive={true}
              customStyles={customStyles}
              theme="default"

              pagination
              dense
              striped
              highlightOnHover={true}
            // onRowClicked={(data) => handleData(data)}

            />
          </Box>)
          : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        <div className="dialog">
          {openDetail ? (
            <IntransitEventsMapping
              onOpen={openDetail}
              onClose={() => setOpenDetail(false)}
              data={EventData}
              line={lineData}
              status={"open"}
              shipmentType={mode}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {legOpen ? (
            <LegInfoVisual
              onOpen={legOpen}
              onClose={() => setLegOpen(false)}
              data={legData}
              line={lineData}
              status={"open"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
        </div>
        <div className="dialog">
          {openLineItems ? (
            <CrLineItems
              onOpen={openLineItems}
              onClose={() => setOpenLineItems(false)}
              data={lineData}
              status={"accepted"}
              sx={{
                "& .MuiDialog-root": {
                  borderRadius: "100px",
                  boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: "9999 !important",
                },
              }}
            />
          ) : null}
          <div>
            {nodata ? (
              <Dialog open={nodata} onClose={handlemap}>
                <Box
                  sx={{
                    width: "350px",
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "20px",
                  }}
                >
                  <Typography variant="h6">No Events To Display</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handlemap}
                    sx={{ width: "25%" }}
                  >
                    OK
                  </Button>
                </Box>
              </Dialog>
            ) : null}
          </div>
          <div>
            <Dialog open={openHistory} onClose={handlemap} maxWidth={'md'} fullWidth={true}>
              {scheduleHistory.length > 0 ?
                <>
                  <Box
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "20px",

                    }}
                  >
                    <Typography variant="h6">Scheduled Delivery History</Typography>
                    <Typography >Container #: &nbsp;&nbsp; {showData?.containerNumber}</Typography>
                  </Box>
                  <Box sx={{ px: 2 }}>
                    <DataTable
                      style={{ borderRadius: 3, cursor: 'pointer' }}
                      columns={columns1}
                      data={scheduleHistory}
                      responsive={true}
                      customStyles={customStyles}
                      theme="default"
                      pagination
                      dense
                      striped
                      highlightOnHover={true}
                    // onRowClicked={(data) => handleData(data)}

                    />
                  </Box>
                  <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                  </DialogActions>
                </> :
                <>
                  <form onSubmit={handleSubmit((data) => {
                    createData(data);
                  })}>
                    <Box
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "20px",
                        justifyContent: 'space-between'

                      }}
                    >
                      <Typography variant="h6">Update Delivery Schedule</Typography>
                      <Typography >Container #: &nbsp;&nbsp; {showData?.containerNumber}</Typography>
                    </Box>
                    <Grid container sx={{ px: 2 }}>

                      <Grid item xs={4}>
                        <InputLabel id="demo-simple-select-label" className="hide-asterisk" sx={{mb:2}}>
                          Delivery Schedule Date
                        </InputLabel>
                        <FormControl>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                label="Select Date"
                                inputFormat="DD/MM/YYYY"
                                value={date}
                                onChange={handleChangeDate}
                                minDate={minDate}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    id="outlined-basic"
                                    variant="outlined"
                                    {...register('date')}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>

                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <Box>
                          <InputLabel id="demo-simple-select-label" >Remarks</InputLabel>
                          <FormControl fullWidth size="small">
                            <TextField
                              {...register('remarks')}
                              id="outlined-basic"
                              multiline
                              fullWidth
                              size='small'
                              className='custom-inputs'
                              rows={4}
                              minRows={4}
                              variant="outlined"
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                    </Grid>
                    <DialogActions>
                      {scheduleHistory.length === 0 && <Button variant="contained" type="submit" size="small" >Update</Button>}
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </form>
                </>
              }



            </Dialog>
          </div>
        </div>
      </Paper >
    </div >
  );
}

export default IntransitEventDisplay;

