import React, { useEffect, useRef, useState } from 'react'
import { FormControl, Grid, InputLabel, Link, TextField, Typography } from '@mui/material';
import { LoadScript } from '@react-google-maps/api';
import { Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import swal from "sweetalert";
import { useOutletContext } from 'react-router-dom';
import { Endpoints } from '../../../Enviornment/Endpoints';
import { toast } from 'react-toastify';
import Loaders from '../../common/Loaders';
export default function AddDutyFreeRetail() {
    const [loader, setLoader] = useState(false);
    const apiKey = 'AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q';
    const [autocomplete, setAutocomplete] = useState(null);
    const [isFormChange, setIsFormChange] = useState(false)
    const [isLatLongEnable, setLatLongEnable] = useState(true)
    const [load, setLoad, mode] = useOutletContext<any>();
    const [apiLoaded, setApiLoaded] = useState(false);
    const [place, setPlace] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const GOOGLE_MAPS_LIBRARIES = ['places'];
    const [address, setAddress] = useState('');
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    
    const inputStyle= {
        boxShadow: 'inset 0 0 10px #eee !important',
        border: '2px solid #eee',
        width: '456px',
        height: '40px',
        marginLeft: '16px',
        borderRadius: '20px',
        fontWeight: '300 !important',
        outline: 'none',
        padding: '10px 20px',
        marginBottom: '10px',
      }
    const { register, resetField, setValue, getValues, reset, formState: { errors }, control, trigger, handleSubmit, } = useForm({
        mode: "onChange",
        defaultValues: {
            shopName: '',
            type: '',
            lat: 0,
            long: 0,
            address: '',
            city: '',
            state: '',
            country: '',
            pincode: '',
            region: ''

        },
    });
    
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const onLoad = (autocomplete: any) => {
        setAutocomplete(autocomplete);
    };
    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = (autocomplete as google.maps.places.Autocomplete).getPlace();
            if (place.geometry && place.geometry.location) {
                const { lat, lng } = place.geometry.location.toJSON();
                setValue('lat', lat);
                setValue('long', lng);
                const placeId = place.place_id;
                if (placeId) {
                    const placesService = new google.maps.places.PlacesService(document.createElement('div'));
                    placesService.getDetails({ placeId }, (result, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK && result !== null) {
                            const address = result.formatted_address || '';
                            const placeName = result.name || ''; // Provide a default value if placeName is undefined
                            const addressComponents = result.address_components;
                            if (addressComponents) {
                                // Find the state and country components
                                const cityComponent = addressComponents.find(component => component.types.includes('locality'));
                                const stateComponent = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
                                const countryComponent = addressComponents.find(component => component.types.includes('country'));
                                const postalCodeComponent = addressComponents.find(component => component.types.includes('postal_code'));
                                // Extract the state and country names
                                const city = cityComponent ? cityComponent.long_name : '';
                                const state = stateComponent ? stateComponent.long_name : '';
                                const country = countryComponent ? countryComponent.long_name : '';
                                const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';
                                const region = getRegionFromAddressComponents(addressComponents);

                                // Now you can use the state and country variables as needed
                                setValue('state', state)
                                setValue('country', country)
                                setValue('city', city)
                                setValue('pincode', postalCode)
                                setValue('region', region);
                            } else {
                                console.log('Address components are undefined.');
                            }
                            setValue('address', address);
                            setValue('shopName', placeName);
                            setValue('type', 'dutyFree')
                        } else {
                            console.log('Error fetching place details:', status);
                        }
                    });
                } else {
                    console.log('Place ID is not available.');
                }

            } else {
                setLatLongEnable(false)
                console.log('Place information is not available.');
            }

        } else {
            setLatLongEnable(false)
            let value = getValues(`shopName`)
            console.log(value, "value")
            console.log('Autocomplete is not loaded yet!');
        }
    };
    const getRegionFromAddressComponents = (addressComponents: any) => {
        const regionComponent = addressComponents.find((component: any) => component.types.includes('administrative_area_level_1'));
        return regionComponent ? regionComponent.long_name : '';
    };
    const createData = (data: any) => {
        console.log(data, "data")
        setLoader(true)
        axios.post(Endpoints.Onboard_Retail, data, config).then((res: any) => {
            setLoader(false)
            console.log(res.data.primary)
            if (res.data.primary) {
                toast.success(res.data.primary)
                reset()
            } else {
                swal('Warning', res.data.status.message, 'warning')
            }
        })
    }
    useEffect(() => {
        setLoad(false)
        if (!isLoaded) {
            // Ensure that the API is loaded only once
            setIsLoaded(true);
          }
        

    }, [isLoaded]);
    
    return (
        <div>
            <form onSubmit={handleSubmit((data) => {
                createData(data);
            })}>
                <Grid container spacing={2} >
                    <Grid item xs={3}>
                        <InputLabel id="demo-simple-select-label"  >  <span>
                            Store Name&nbsp;<span style={{ color: 'red' }}>*</span>
                        </span></InputLabel>
                        <LoadScript googleMapsApiKey={apiKey} libraries={["places"]} onLoad={() => console.log('Google Maps API loaded')} {...(!isLoaded && { async: true })}>
                            <GoogleAutocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} className="custom-autocomplete">
                                <TextField

                                    fullWidth size='small' className='custom-inputs' {...register(`shopName`)}
                                    sx={{
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: '#0B5583'
                                        },
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        },

                                    }} />
                            </GoogleAutocomplete>
                        </LoadScript> 

                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel id="demo-simple-select-label" >Latitude</InputLabel>
                        <FormControl fullWidth size="small">
                            <TextField
                                {...register(`lat`,
                                    { required: 'Latitude is required' }
                                )}

                                id="outlined-basic"
                                type="text"
                                disabled={true}
                                fullWidth
                                className='custom-inputs'
                                sx={{
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        // color: "rgb(211, 47, 47)", // Change the label color when focused
                                        color: '#0B5583'
                                    },
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        // borderColor: "rgb(211, 47, 47)", // Change the border color when focused
                                        // color:'#dd'
                                    },

                                }}
                                size='small'
                            />
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.lat?.message}
                            </span>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel id="demo-simple-select-label" >Longitude</InputLabel>
                        <FormControl fullWidth size="small">
                            <TextField
                                {...register(`long`,
                                    { required: 'Longitude is required' }
                                )}

                                id="outlined-basic"
                                type="text"
                                fullWidth
                                disabled={true}
                                className='custom-inputs'
                                sx={{
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        // color: "rgb(211, 47, 47)", // Change the label color when focused
                                        color: '#0B5583'
                                    },
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        // borderColor: "rgb(211, 47, 47)", // Change the border color when focused
                                        // color:'#dd'
                                    },

                                }}
                                size='small'
                            />
                            <span style={{ color: "red", fontSize: "12px" }}>
                                {errors.long?.message}
                            </span>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <LoadingButton
                            sx={{
                                mt: 3, textAlign: 'center',

                                '&.MuiLoadingButton-loading': {
                                    backgroundColor: '#2d7fb7 !important',
                                }
                            }}
                            variant="contained"
                            size='small'
                            type='submit'
                            loading={loader}


                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
