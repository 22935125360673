import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { sideBar, defaultColDef } from '../../common/AgGridConfig';
import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FirstDataRenderedEvent, GridReadyEvent } from "ag-grid-community";
// Icons Imports
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import "./trcuk.css";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Container } from "@mui/system";
import { makeStyles, Theme } from "@material-ui/core/styles";

import axios from "axios";
import { Environment_Realtraceb_Dev } from "../../../Enviornment/Environment_Realtraceb_Dev";
import { TransitionProps } from "@mui/material/transitions";
import DataTable, { TableColumn } from "react-data-table-component";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    fontSize: "10px",
    // fontWeight: "bold",
    minHeight: "100%",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));
const customStyles = {
  headRow: {
    style: {
      border: 'none',
      backgroundColor: '#0b51b7',

    },
  },
  headCells: {
    style: {
      fontSize: '14px',
      color: 'white'
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
      // cursor:'pointer'
    },
  },

};
function WhLineItems(props: any) {
  console.log(props.data);
  const [showgrid, setShowGrid] = useState(true);
  const [rowData, setRowData] = useState<any>([])
  const [open, setOpen] = useState(false)
  const columns: TableColumn<any>[] = [
    {
      name: "COMMODITY TYPE",
      sortable: true,
      selector: row => row.truckDetails !== '' && row.truckDetails !== null && row.truckDetails !== undefined ? row.truckDetails.truckNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px',
    },

    {
      name: "ITEM NAME",
      sortable: true,
      selector: row => row.itemName !== '' && row.itemName !== null && row.itemName !== undefined ? row.itemName : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "ITEM DESCRIPTION",
      sortable: true,
      selector: row => row.truckDetails !== '' && row.truckDetails !== null && row.truckDetails !== undefined ? row.truckDetails.driverName : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "BATCH #",
      sortable: true,
      selector: row => row.gateData !== '' && row.gateData !== null && row.gateData !== undefined ? props.event !== 'gateOut' ? row.gateData.gateInTime : row.gateData.gateOutTime : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "LOT #",
      sortable: true,
      selector: row => row.gateData !== '' && row.gateData !== null && row.gateData !== undefined ? props.event !== 'gateOut' ? row.gateData.gateInTime : row.gateData.gateOutTime : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "SERIAL #",
      sortable: true,
      selector: row => row.gateData !== '' && row.gateData !== null && row.gateData !== undefined ? props.event !== 'gateOut' ? row.gateData.gateInTime : row.gateData.gateOutTime : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "PACKAGE TYPE",
      sortable: true,
      selector: row => row.gateData !== '' && row.gateData !== null && row.gateData !== undefined ? props.event !== 'gateOut' ? row.gateData.gateInTime : row.gateData.gateOutTime : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "PACKAGE WEIGHT",
      sortable: true,
      selector: row => row.totalWeight !== '' && row.totalWeight !== null && row.totalWeight !== undefined ?  row.totalWeight : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    // {
    //   name: "PACKAGE LENGTH",
    //   sortable: true,
    //   selector: row => row.gateData !== '' && row.gateData !== null && row.gateData !== undefined ? props.event !== 'gateOut' ? row.gateData.gateInTime : row.gateData.gateOutTime : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    //   width: '200px'
    // },
    // {
    //   name: "PACKAGE WIDTH",
    //   sortable: true,
    //   selector: row => row.gateData !== '' && row.gateData !== null && row.gateData !== undefined ? props.event !== 'gateOut' ? row.gateData.gateInTime : row.gateData.gateOutTime : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    //   width: '200px'
    // },
    // {
    //   name: "PACKAGE HEIGHT",
    //   sortable: true,
    //   selector: row => row.gateData !== '' && row.gateData !== null && row.gateData !== undefined ? props.event !== 'gateOut' ? row.gateData.gateInTime : row.gateData.gateOutTime : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
    //   width: '200px'
    // },
    {
      name: "TOTAL VOLUME",
      sortable: true,
      selector: row => row.volume !== '' && row.volume !== null && row.volume !== undefined ?  row.volume : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "VOLUME UOM",
      sortable: true,
      selector: row => row.volumeUOM !== '' && row.volumeUOM !== null && row.volumeUOM !== undefined ?  row.volumeUOM : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "HZ GOODS",
      sortable: true,
      selector: row => row.hzGoods !== '' && row.hzGoods !== null && row.hzGoods !== undefined ? row.hzGoods : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },
    {
      name: "SPECIAL INSTRUCTIONS",
      sortable: true,
      selector: row => row.gateData !== '' && row.gateData !== null && row.gateData !== undefined ? props.event !== 'gateOut' ? row.gateData.gateInTime : row.gateData.gateOutTime : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
      width: '200px'
    },

  ]
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {},
  };
  useEffect(() => {
    console.log("TruckLine Items Screen... ");
    setRowData([props.data])
  }, []);


  const classes = useStyles();
  const handleClose = () => {
    setOpen(props.onClose)
  }




  return (
    <div>
      <Dialog
        maxWidth="md"
        open={props.onOpen}
        fullWidth={true}
        onClose={props.onClose}
      >
        <DialogTitle>
          <Box
            sx={{
             
              display: "flex",
              flexDirection: "column",
              borderRadius: "20px",
              justifyContent: 'space-between'

            }}
          >
            <Typography variant="h6">WareHouse Details</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

            <Typography >Container #: &nbsp;&nbsp; {props.data?.containerNumber || props.data?.ContainerNumber}</Typography>
            <Typography >WareHouse Booking Reference #: &nbsp;&nbsp; {props.data?.warehouseBookingReferenceNumber || props.data?.WhBookingRefNo}</Typography>
          </Box>
        </DialogTitle>
        <Box
          sx={{
            paddingLeft: "30px",
            paddingBottom: "30px",
            paddingRight: "30px",
          }}
        >
          {showgrid ? (
            <DataTable
              style={{ borderRadius: 3, cursor: 'pointer' }}
              columns={columns}
              data={rowData}
              responsive={true}
              customStyles={customStyles}
              theme="default"

              pagination
              dense
              striped
              highlightOnHover={true}
            // onRowClicked={(data) => handleData(data)}

            />
          ) : (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              No data found
            </Alert>
          )}
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WhLineItems;
