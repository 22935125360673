import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  TextField,
  Container,
  Box,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Paper,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import swal from "sweetalert";

import { Formik, Form, Field } from "formik";
import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { useOutletContext } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useFieldArray, useForm } from "react-hook-form";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
const FileUploadValidation = Yup.object().shape({
  Hbl: Yup.string().required("HBL Number Required"),
  doctype: Yup.string().required("Doctype Required"),
  Reference: Yup.string().required("Reference Required"),
  FileName: Yup.string().required("FileName is Required"),
});
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    fontSize: "10px",
    // fontWeight: "bold",
    "& .MuiDialog-root": {
      borderRadius: "100px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      zIndex: "9999 !important",
    },
  },
  maincontent: {},
  tab: {
    backgroundColor: "#f1f1f1",
    marginBottom: "10px",
  },
}));

function CommonFileUploader() {
  const [fileExtError, setFileExtError] = useState<boolean>(false);
  const [Machineindexes, setMachineIndex] = React.useState<any>(0)
  const [file, setFile] = useState<any>();
  const gridRef = useRef<any>();
  const [ExcelName, setExcelName] = useState<string>("");
  const [rerender, setRerender] = useState(0)
  const [masterData, setMaterData] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState('Ocean');
  const [docData, setDocData] = useState<any>([]);
  const [masterDataId, setMasterDataid] = useState<string>("");
  const classes = useStyles();
  const [refNumber, setRefNumber] = useState<any>("");
  const [doctype, setDocType] = useState<any>("");
  const [doctypeid, setDocTypeId] = useState<any | null>('');
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [selectedKey, setSelectedKey] = useState([]);
  const [loginRole, setLoginRolre] = useState<any>(
    sessionStorage.getItem("role")
  );
  const [role, setRole] = useState<string>("");
  const [reference, setReference] = useState("Enter Reference Number");
  const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
  const FormRef = useRef<any>();

  const goptions = {
    documentReference: "9703196928",
  };

  const gconfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: goptions,
  };

  function getDocument() {
    axios.get(process.env.REACT_APP_PREPROCESSOR_API + "api/v1/documents/getDocs",
      gconfig
    )
      .then((res: any) => console.log(res));
  }

  const options = {
    _id: masterDataId,
  };

  let getConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
    params: {
      shipmentType: mode
    }
  };
  useEffect(() => {
    setRole(loginRole);
    setLoad(false)
    console.log(typeof sessionStorage.getItem("role"));
    getmasterlist();
    setMasterDataid("")
    setRefNumber("")
    setDocTypeId("")
    setRerender(rerender + 1)

  }, [fromDate, toDate, format, mode]);

  function filevalidation(e: any) {
    console.log("e is", e.file[0].name)
    let split = e.file[0].name.split(".");
    console.log(e.file[0], "file");
    if (split[1] !== "xlsx") {
      console.log(split);
      setFileExtError(true);
    } else {
      setFileExtError(false);
    }
    setFile(e.file[0]);
    setExcelName(e.file[0].name);

  }

  function getmasterlist() {
    console.log("i am calling")
    axios
      .get(process.env.REACT_APP_PREPROCESSOR_API + "api/v1/documents/getMasterDocAgainst",
        getConfig,
      )
      .then((res: any) => {
        setMaterData(res.data.data);
        console.log("1234567899876543", res.data.data);
        setSelectedKey([])
        setDocData([])

        // getDocument();
      });
  }

  function getchildlist(e: any) {

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      params: {
        _id: e,
      }

    };
    axios.get(process.env.REACT_APP_PREPROCESSOR_API + "api/v1/documents/getMasterDocTypes",
      config
    )
      // /getMasterDocTypes?_id=63bcf9bd3e00ddef757819e2
      .then((res: any) => {
        setDocData(res.data.data);
        console.log("1234567899876543", res.data.data);
      }).catch((e) => console.log(e))



  }


  const [dtype, setDtype] = useState("")

  function handlehbltype(e: any, index: any) {
    let ref = "Enter " + e.target.name + " Reference Number";
    setReference(ref);
    console.log("TargetData:", e);
    setSelectedKey(index);

    setMasterDataid(e.target.value);
    // getchildlist('')s
    // getchildlist(e.target.value)
    getchildlist(e.target.value)
  }
  function handledoctype(e: any) {
    console.log("Document Type Id...", e.documentAgainstId)
    setDocTypeId(e.documentAgainstId);
  }
  function handlefiletype(e: any) {
    setMasterDataid(e.target.value);
  }
  function handlereftype(e: any) {
    console.log("234567987654345678", e);
    setRefNumber(e.target.value);
  }

  const uploadsFile = (data: any) => {
    let length = data.data.length;
    console.log("length...", length, data.data)
    for (let i = 0; i <= length; i++) {
      console.log("data is ....", data.data[i]);
      // filevalidation(data.data[i])
      // filevalidation(data.data[i])
      uploadfile(data.data[i])
    }


  }

  function uploadfile(data: any) {
    // let role=sessionStorage.getItem("role");
    console.log("Data is ...", data.file[0])
    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("documentAgainstId", data.documentAgainstId);
    formData.append("documentTypeId", data.documentTypeId);
    formData.append("documentReference", data.documentReference);
    formData.append("uploadedUserRole", loginRole);
    // let options = {
    //   documentAgainstId: masterDataId,
    //   documentTypeId: doctypeid,
    //   documentReference: refNumber,
    //   uploadedUserRole: sessionStorage.getItem("role"),
    //   file: formData,
    // };

    console.log(formData)

    const config = {
      headers: {
        "Content-Type": "multipart/form-date",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    setLoad(true)
    axios.post(process.env.REACT_APP_PREPROCESSOR_API + "api/v1/docUpload",
      formData,
      config
    )
      .then((res: any) => {
        if (res.data.status) {
          setLoad(false)
          swal("Success", res.data.msg, "success");
        }
      }).catch((e: any) => {
        setLoad(false);
        console.log(e.response.data.msg)
        toast.error(e.response.data.msg)
      });

    reset()
  }

  const validatefields = () => {

    if (masterDataId && doctypeid && refNumber && file) {
      return false;
    }
    return true;
  };

  const clearFields = () => {
    const formData = new FormData();
    FormRef.current.reset();
    setMasterDataid("");
    setDocTypeId("");
    setRefNumber("");
    setRerender(rerender + 1)
    file([]);
  };
  function handleAlert() {
    setShowAlert(false);
  }
  // const reset = () => {
  //   // setRowData([]);
  //   // setShowFile(false);
  //   // FormRef.current.reset();
  //   clearFields();
  //   setExcelName("Selcet File");
  //   setFile(undefined);
  //   setDocData([])
  //   setSelectedKey([])
  // };

  const { register, resetField, setValue, getValues, formState: { errors }, reset, control, trigger, handleSubmit, } = useForm({
    mode: "onChange",
    defaultValues: {
      data: [{
        file: '',
        documentAgainstId: '',
        documentTypeId: '',
        documentReference: '',

      }]
    }
  })

  const { fields, append: adddocumnets, remove: removedocs } = useFieldArray({
    name: "data",
    control,
    rules: {
      required: "Enter All Details",
    },

  });

  const addDocs = () => {
    setMachineIndex(() => Machineindexes + 1)
    adddocumnets({
      file: '',
      documentAgainstId: '',
      documentTypeId: '',
      documentReference: ''
    })
  }

  const removedoc = (index: any) => {
    setMachineIndex(() => Machineindexes - 1)
    removedocs(index)
  }


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    // if (event.target.value === 'Ocean') {
    //   let a = gridRef.current.columnApi.getAllColumns().map((ele: any) => {
    //     if (ele.userProvidedColDef.hide != undefined) {
    //       ele.userProvidedColDef.hide = !ele.userProvidedColDef.hide
    //     }
    //     return ele.userProvidedColDef
    //   });
    //   gridRef.current.api.setColumnDefs(a)
    //   autosize()
    // } else {
    //   let a = gridRef.current.columnApi.getAllColumns().map((ele: any) => {
    //     if (ele.userProvidedColDef.hide != undefined) {
    //       ele.userProvidedColDef.hide = !ele.userProvidedColDef.hide

    //     }
    //     return ele.userProvidedColDef
    //   });
    //   gridRef.current.api.setColumnDefs(a)
    //   autosize()
    // }

  };
  const autosize = useCallback(() => {

    var allColumnIds: any = [];
    gridRef.current.columnApi.getAllColumns().map((ele: any) => {
      return allColumnIds.push(ele.colId);
    });
    gridRef.current.columnApi.autoSizeAllColumns(false);
    console.log(gridRef.current.columnApi);

  }, []);
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <form onSubmit={handleSubmit((data: any) => {
          uploadsFile(data);
          // setOpen(true);
        })}>
          {fields.map((field, index) => {

            return (
              <Box key={index}>
                {index < 5 &&
                  <Grid item container spacing={2} key={field.id} sx={{ mb: 2 }}>
                    <Grid item xs={2.5}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="select-File">
                          Upload Document Against
                        </InputLabel>
                        <Select
                          labelId="select-File"
                          id="demo-simple-select"
                          {...register(`data.${index}.documentAgainstId`, {
                            required: '* Document Against is Required'
                          })}
                          label="Upload Document Against"
                          // onChange={handlehbltype}
                          onChange={(e: any) => { setValue(`data.${index}.documentAgainstId`, e.target.value, { shouldValidate: true }); handlehbltype(e, index) }}

                          MenuProps={{ disableScrollLock: true }}
                        >
                          {masterData.length
                            ? masterData.map((value: any) => (
                              <MenuItem key={value._id} value={value._id}>
                                {value.name}
                              </MenuItem>
                            ))
                            : null}
                        </Select>


                      </FormControl>
                      <span style={{ color: 'red', fontSize: '12px' }}>
                        {errors.data?.[index]?.documentAgainstId?.message}
                      </span>
                    </Grid>
                    <Grid item xs={2.5}>



                      <Autocomplete
                        key={rerender}
                        size="small"
                        options={docData}
                        getOptionLabel={(option: any) => option.name} // Display name
                        onChange={(event, newValue: any) => {
                          handledoctype(newValue); // Store ID
                          setValue(`data.${index}.documentTypeId`, newValue._id, { shouldValidate: true }); // Update form field with ID
                        }}
                        renderInput={(params) => (
                          <TextField
                            // required
                            {...params}
                            value={getValues(`data.${index}.documentTypeId`)} // Set the field value
                            label="Document Type"

                            onChange={(e) => {
                              setValue(`data.${index}.documentTypeId`, e.target.value); // Update value in the form
                            }}
                          />
                        )}
                      />
                      <span style={{ color: 'red', fontSize: '12px' }}>
                        {errors.data?.[index]?.documentTypeId?.message}
                      </span>



                    </Grid>
                    <Grid item xs={2.5}>
                      <FormControl fullWidth size="small">
                        <TextField
                          key={field.id}
                          type="text"
                          label="Enter Reference Number"
                          {...register(`data.${index}.documentReference`, {
                            required: " * Document Reference Number is required"
                          })}
                          size="small"
                          onChange={(e: any) => { setValue(`data.${index}.documentReference`, e.target.value, { shouldValidate: true }); handlereftype(e) }}
                        />
                        <span style={{ color: 'red', fontSize: '12px' }}>
                          {errors.data?.[index]?.documentReference?.message}
                        </span>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2.5}>
                      <TextField
                        type="file"
                        placeholder="Choose file"
                        required
                        {...register(`data.${index}.file`)}
                        onChange={filevalidation}
                        size="small"
                      />
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.data?.[index]?.file?.message}
                      </span>
                    </Grid>
                    <Grid item xs={1} >
                      {index == Machineindexes ?
                        <div>
                          {Machineindexes < 4 ?
                            <div>
                              {/* <IconButton onClick={() => addDocs()}>
                                <AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} />
                              </IconButton> */}
                              {index != 0 && <IconButton onClick={() => removedoc(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}
                              <IconButton onClick={() => addDocs()}>
                                <AddCircleOutlineOutlinedIcon sx={{ color: 'green' }} />
                              </IconButton>
                            </div>
                            : <IconButton onClick={() => removedoc(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>
                          }
                        </div> :
                        <IconButton onClick={() => removedoc(index)}><RemoveCircleOutlineOutlinedIcon sx={{ color: 'tomato' }} /></IconButton>}
                    </Grid>
                  </Grid>
                }
              </Box>

            )
          })}

          <Grid container sx={{ mt: 2 }} spacing={2}>

            <Grid item>
              {/* {validatefields() === true ? ( */}
              <Button variant="contained" type="submit">
                Submit
              </Button>
              {/* ) : (
                <Button
                  variant="contained"
                  color="success"
                  onClick={uploadfile}
                >
                  Submit
                </Button>
              )} */}
            </Grid>
            <Grid item>
              <LoadingButton
                loading={false}
                variant="contained"

                className="submitbtn"
                sx={{
                  justifyContent: "end",
                  fontWeight: 500,
                  ml: 2,
                  backgroundColor: "#555a64",
                  color: "#fff",
                  "& .MuiButtonBase-root:hover": {
                    backgroundColor: "#555a64",
                    color: "#fff",
                  },
                }}
                onClick={(event) => { reset(); setMachineIndex(0) }}
              >
                Reset
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
        <Dialog open={showAlert} onClose={handleAlert}>
          <Box sx={{ padding: "25px", borderRadius: "5px" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              {/* <img
              src={require("../asserts/sucess.png")}
              alt="sucess Image"
              width={60}
              height={60}
            /> */}
            </Box>
            <Typography sx={{ marginBottom: "15px" }}>{message}</Typography>

            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" color="success" onClick={handleAlert}>
                Ok
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Paper>
      <ToastContainer style={{ zIndex: 10000 }} />
    </div>
  );
}

export default CommonFileUploader;
