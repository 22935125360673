import {
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Breadcrumbs,
  Link,
} from "@mui/material";
import swal from "sweetalert";
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import colorConfigs from "../../Config/colorConfig";
import sizeConfigs from "../../Config/sizeConfigs";
import NotificationsIcon from "@material-ui/icons/Notifications";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AccountCircle } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SearatesInfo from "../pages/SeaRates/SearatesInfo";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
const Topbar = (props: any) => {
  const location = useLocation();
  // console.log(props, "props");
  const [headName, setHeadName] = useState(
    props.head != "" ? props.head : "DashBoard"
  );
  const [currentTime, setCurrentTime] = useState("");
  const [shipmentType, setShipmentType] = useState<any>('Ocean')
  useEffect(() => {
    // console.log("HEADER NAME", props.head);
    setHeadName(props.head);
    setInterval(currentDateTime, 1000);
  }, [props, shipmentType]);
  const userRole = sessionStorage.getItem("role")?.toUpperCase();
  const navigate = useNavigate();
  const [open, SetOpen] = useState(false);
  const [anchorEl, SetAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, SetIsOpenMenu] = useState(false);
  const [filterOpen, SetFilterOpen] = useState(false);
  const [ratesOpen, setseaRatesOpen] = useState(false);

  const currentDateTime = () => {
    setCurrentTime(moment().format("Do MMMM  YYYY H:mm:ss"));
  };
  const handleMenuClose = () => {
    SetAnchorEl(null);
    SetIsOpenMenu(false);
  };
  const handleClose = () => {
    SetOpen(false);
  };
  const handleFilters = () => SetFilterOpen(!filterOpen);

  const submitShipmentType = (type: any) => {
    setShipmentType(type)
    sessionStorage.setItem('shipmentType', type)
    props.modeSet(type)
  }

  const handleProfileMenuOpen = (event: any) => {
    SetAnchorEl(event.currentTarget);
    SetIsOpenMenu(true);
    swal({
      title: "Are you sure you want to logout ?",
      icon: "info",
      customClass: "custom-swal-class",
      buttons: {
        delete: "Logout",
        cancel: "Cancel",
      },
    } as any).then((result: any) => {
      if (result === 'delete') {
        handleLogOut()
      }
    })

  };
  const handleLogOut = () => {
    SetAnchorEl(null);
    SetIsOpenMenu(false);
    sessionStorage.clear();
    navigate("/login");
  };
  const checkSearates = () => {
    navigate('/sea_rates')
    // setseaRatesOpen(true)
  }
  const registration = () => {
    navigate('/registrations')
  }
  return (
    <AppBar
      position="absolute"
      sx={{
        position: 'fixed',
        width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        ml: sizeConfigs.sidebar.width,
        boxShadow: "0px 0px 5px gray",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ fontSize: '1rem', letterSpacing: '1px' }}>
          REALTRACE <span style={{ color: "#0b51b7" }}></span>
        </Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Button
            sx={{ display: 'flex', alignItems: 'center' }}
            variant="text"
            color={shipmentType === 'Ocean' ? 'primary' : 'inherit'}
            onClick={() => submitShipmentType('Ocean')}

          >
            <img
              src={shipmentType === 'Ocean' ? require("../../asserts/New-Icons/Ocean(1).png") : require("../../asserts/New-Icons/ocean.png")}
              className={shipmentType === 'Ocean' ? "ti ti-users font-24 align-self-center text-muted" : 'ti ti-users font-24 align-self-center text-muted custom-icon-colors'}
            />&nbsp;
            (Ocean)
          </Button>
          <Button
            variant="text"
            color={shipmentType === 'Ocean' ? 'inherit' : 'primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
            onClick={() => submitShipmentType('Air')}
          >
            <img
              src={shipmentType === 'Air' ? require("../../asserts/New-Icons/Air(1).png") : require("../../asserts/New-Icons/Air2.png")}
              className={shipmentType === 'Ocean' ? "ti ti-users font-24 align-self-center text-muted custom-icon-colors" : 'ti ti-users font-24 align-self-center text-muted'}
            />&nbsp;
            (Air)
          </Button>
        </Breadcrumbs>
        {/* <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                    sx={{
                        marginTop: "10px",
                        marginRight: " 15px",
                        color: "#271a78",
                        boxShadow: "none",
                    }}
                >
                    <NotificationsIcon />
                </IconButton> */}
        <Typography>{currentTime}</Typography>

        <Typography
          sx={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <IconButton><HowToRegOutlinedIcon sx={{ cursor: "pointer" }} onClick={() => registration()}/></IconButton> */}
          {process.env.REACT_APP_ENV != 'multitenancy' ? <span>Freight Quote <IconButton sx={{ mr: 1 }}><CalendarMonthIcon sx={{ cursor: "pointer" }} onClick={() => checkSearates()} /></IconButton></span> : ''}
          <strong
            style={{
              letterSpacing: "0.6px",
              fontWeight: "bold",
              textTransform: "uppercase",
              marginRight: "10px",
              fontSize: '0.85rem'
            }}
          >
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <span>
                {sessionStorage.getItem('email')}
              </span>
              <span style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '0.68rem', color: '#1976d2', fontStyle: 'italic' }}>
                {sessionStorage.getItem('Organisation')}
              </span>
            </span>
          </strong>{" "}
          <Button
            variant="contained"
            sx={{ color: "white", borderRadius: '20px', padding: '7px', minWidth: '10px' }}
            size="small"
            onClick={handleProfileMenuOpen}

          >
            <LogoutIcon />

          </Button>&nbsp;

        </Typography>


      </Toolbar>

    </AppBar>
  );
};

export default Topbar;
