import { GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Endpoints } from '../Enviornment/Endpoints';
import { Alert, AlertTitle } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

function RouteMap(props: any) {
  let Api_Config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    params: {}
  }
  //Map Markers
  let icon: any =
    "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000";
  let iconbetween: any =
    "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|008000";
  let iconorigin: any =
    "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|f4c919";
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [originInfoWindow, setOriginInfoWindow] = useState(false);
  const [destinationInfoWindow, setDestinationInfoWindow] = useState(false);
  const [seaRouteCoordinates, setseaRouteCoordinates] = useState<any>([])
  const [centerCoordinates, setCenterCoordinates] = useState<any>()
  const [load, setLoad] = useOutletContext<any>();
  const [isMapShow, setIsMapShow] = useState<any>(false)
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q",
  });

  const mapOptions = {
    zoom: 3,
    styles: [
      {
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [
          {
            color: '#e2e2e2',
          }
        ]
      }
    ]
  };
  const dottedLineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 2,
  };
  const handleMarkerClick = (position: any) => {
    console.log(position, "position")
    setInfoWindowPosition(position);
  };
  useEffect(() => {
    // console.log(props,"props")
    getRouteMapCoordinates()
    // // Trigger the ripple effect when the component mounts
    // setDestinationInfoWindow(true);

    // // Cleanup the ripple effect after a delay (adjust the delay as needed)
    // const timeoutId = setTimeout(() => {
    //   setDestinationInfoWindow(false);
    // }, 1500);

    // return () => clearTimeout(timeoutId);
  }, []);
  const getRouteMapCoordinates = () => {
    let dataPayload = {
      docReference: props.docRef
    }
    Api_Config.params = dataPayload
    setLoad(true)
    axios.get(Endpoints.getRouteMapCoordinates, Api_Config).then((res: any) => {
      setLoad(false)
      if (res.data.primary?.coordinates) {
        if (res.data.primary?.coordinates.length > 0) {
          console.log(JSON.parse(res.data.primary.coordinates), "dataaaaa")
          setseaRouteCoordinates(JSON.parse(res.data.primary.coordinates))
          setCenterCoordinates({
            lat: (JSON.parse(res.data.primary.coordinates)[0]?.lat + JSON.parse(res.data.primary.coordinates)[1]?.lat) / 2,
            lng: (JSON.parse(res.data.primary.coordinates)[0]?.lng + JSON.parse(res.data.primary.coordinates)[1]?.lng) / 2,
          });
          setOriginInfoWindow(true);
          setDestinationInfoWindow(true);
          setIsMapShow(true)
        } else {
          setIsMapShow(false)
          return (
            <Alert severity="info">
              <AlertTitle>Info</AlertTitle>
              The shipment route haven't been identified yet.
            </Alert>
          )
        }
      } else {
        setIsMapShow(false)
        return (
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            The shipment route haven't been identified yet.
          </Alert>
        )
      }
    }).catch((error) => {
      setLoad(false)
      console.log(" response error " + JSON.stringify(error));
      return error;
    });
  }
  return (
    <div>
      {/* {isMapShow ? */}
        <div>
          {isLoaded ?
              isMapShow ?
              <GoogleMap
                mapContainerStyle={{ height: "80vh", width: "70vw" }}
                center={centerCoordinates}
                zoom={mapOptions.zoom}
                options={{
                  styles: mapOptions.styles,
                  mapId: 'your-map-id',
                }}
              >
                <Polyline
                  path={seaRouteCoordinates}
                  options={{
                    strokeColor: '#007BFF',
                    strokeWeight: 2,
                  }}
                />
                {/* <Polyline
            path={seaRouteCoordinates.slice(35, seaRouteCoordinates.length)}
            options={{
              strokeColor: '#ffb217',
              strokeOpacity: 0,
              strokeWeight: 5,
              icons: [
                {
                  icon: dottedLineSymbol,
                  offset: '0',
                  repeat: '10px', // Adjust the repeat value to change the spacing of the dots
                },
              ],
            }}
          /> */}
                {/* -------------- origin marker -------------- */}
                <Marker
                  position={seaRouteCoordinates[0]}
                  icon={iconorigin}
                  onClick={() => handleMarkerClick(seaRouteCoordinates[0])}
                />
                {/* <Marker
            position={seaRouteCoordinates[35]}
            icon={iconbetween}
          // icon={{
          //   path: "M10 0a10 10 0 1 0 20 0a10 10 0 1 0-20 0",
          //   scale: 0.8,
          //   fillColor: "#173a64",
          //   fillOpacity: 1,
          //   strokeColor: "#173a64",
          //   strokeWeight: 2
          // }}
          // onClick={() => handleMarkerClick(seaRouteCoordinates[5])}
          /> */}
                <Marker
                  position={seaRouteCoordinates[seaRouteCoordinates.length - 1]}
                  icon={icon}
                  onClick={() => handleMarkerClick(seaRouteCoordinates[seaRouteCoordinates.length - 1])}
                />
                {originInfoWindow && (
                  <InfoWindow
                    position={seaRouteCoordinates[0]}
                    onCloseClick={() => setOriginInfoWindow(false)}
                    options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                  >
                    <div>Origin - {props.origin}</div>
                  </InfoWindow>
                )}
                {destinationInfoWindow && (
                  <InfoWindow
                    position={seaRouteCoordinates[seaRouteCoordinates.length - 1]}
                    onCloseClick={() => setDestinationInfoWindow(false)}
                    options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                  >
                    <div>Destination - {props.destination}</div>
                  </InfoWindow>
                )}
              </GoogleMap> : "" :''
          }
        </div> 
        {!isMapShow &&
        <Alert severity="info">
          <AlertTitle>Info</AlertTitle>
          The shipment route haven't been identified yet.
        </Alert>}

    </div >
  )

}

export default RouteMap;