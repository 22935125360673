import { createSlice } from "@reduxjs/toolkit";

const initialState:any = [];
const ShipmentRegistrationSlice = createSlice({
    name: 'ShipmentRegistrationSlice',
    initialState,
    reducers: {
        setShipmentData(state, action) {
            console.log("Payload is ...", action)
            // state.push(action.payload)
            return {
                ...state,
                ...action.payload,
            };
        }, resetShipmentData(state, action) {
            return initialState;
          },
    }
})

export const { setShipmentData,resetShipmentData } = ShipmentRegistrationSlice.actions;
export default ShipmentRegistrationSlice.reducer;