import {
    Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, FormControl, SelectChangeEvent, InputLabel, MenuItem, OutlinedInput, Checkbox, ListItemText, Alert, AlertTitle, IconButton, List, ListItemButton, ListItemIcon, Divider, InputBase, Select,
    Tooltip,
} from '@mui/material';
import { sideBar, defaultColDef } from '../components/common/AgGridConfig';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import '../Styles.css'
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { Endpoints } from '../Enviornment/Endpoints';
import { FirstDataRenderedEvent, GridReadyEvent } from 'ag-grid-community';
import { useOutletContext } from 'react-router-dom';
import Loaders from '../components/common/Loaders';
import DataTable, { TableColumn } from 'react-data-table-component';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import { MultiSelect } from "react-multi-select-component";
import moment from 'moment';
import IntransitEventsMapping from './IntransitEventsMapping';

declare var $: any;
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7'
        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
            // cursor: 'pointer'
        },
    },

};
export default function CommonInTransitInfo(props: any) {
    const [rowData, setRowData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTerm1, setSearchTerm1] = useState('');
    const [shipmentsCount, setShipmentsCount] = useState<any>([])
    const [lineData, setLineData] = useState([]);
    const gridRef = useRef<any>();
    const domLayout = "autoHeight";
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [destinationCountries, setDestinationCountries] = useState<any>([])
    const [EventData, setEventData] = useState<any>([]);
    // const [load, setLoad] = useOutletContext<any>();
    const [openDetail, setOpenDetail] = useState(false);
    const [selectedItems, setSelectedItems] = useState<any>([])
    const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
    const handleSearch = (event: any) => {
        setSearchTerm(event.target.value);
    };
    const handleClear = () => {
        setSearchTerm('')
    }
    // const filterData = rowData.filter((data: any, index: any) =>
    //     Object.values(data).some((value: any) =>
    //         value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    //     )
    // );
    const filterData = rowData.filter((data: any) => {
        return Object.values(data).some((value) => {
            if (value === null || value === undefined || value === '') {
                // Include null and undefined values
                return false;
            }
            return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
        });
    });
    console.log(filterData, "filter")
    const columns: TableColumn<any>[] = [
        // {
        //     name: "INVOICE #",
        //     selector: row => row.invoiceNumber !== '' && row.invoiceNumber !== null && row.invoiceNumber != undefined ? <Tooltip title={row.invoiceNumber} arrow><span style={{cursor:'pointer'}}>{row.invoiceNumber}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
        //     width: '220px'
        // },
        {
            name: "INVOICE #",
            // selector: row => (
            //     row.invoiceNumber !== '' && row.invoiceNumber !== null && row.invoiceNumber !== undefined
            //         ? row.invoiceNumber
            //         : '- NA -'
            // ),
            width: '200px',

            cell: (row) => <a
                style={{
                    width: "100%",
                    lineHeight: 0.5,
                    color: "blue",
                }}
                className="btn btn-info"
                onClick={() => handleData(row)}
            >
                {row.invoiceNumber !== '' && row.invoiceNumber !== null && row.invoiceNumber !== undefined ? <span style={{ color: '#5fb25c', cursor: 'pointer', }}>{row.invoiceNumber}</span> : <span style={{ color: '#ad2f33', cursor: 'pointer', }}>- NA -</span>}
            </a>

        },
        {
            name: "HBL #",
            selector: row => row.hblNumber !== '' ? row.hblNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
        },
        {
            name: "HAWB #",
            sortable: true,
            selector: row => row.hawbNumber !== '' ? row.hawbNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
            width: '200px',
        },
        {
            name: "MBL #",
            selector: row => (
                row.mblNumber !== '' && row.mblNumber !== null && row.mblNumber !== undefined
                    ? row.mblNumber
                    : '- NA -'
            ),
            style: (row: any) => ({
                // cursor: row.mblNumber !== '- NA -' ? 'pointer' : 'default',
            }),
            width: '150px',
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
        },
        {
            name: "MAWB #",
            sortable: true,
            selector: row => row.awbNumber !== '' ? row.awbNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
            width: '200px',

        },
        {
            name: "CONTAINER #",
            selector: row => (
                row.containerNumber !== '' && row.containerNumber !== null && row.containerNumber !== undefined
                    ? row.containerNumber
                    : '- NA -'
            ),
            style: (row: any) => ({
                // cursor: row.containerNumber !== '- NA -' ? 'pointer' : 'default',
            }),
            width: '250px',
            omit: props.pageInfo.shipmentType == 'Air' ? true : false,
        },
        // {
        //     name: "FLIGHT #",
        //     selector: row => row.flightNumber !== '' ? row.flightNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
        //     width: '150px',
        //     omit: props.pageInfo.shipmentType == 'Ocean' ? true : false,
        // },
        {
            name: "CARRIER",
            selector: row => (
                row.carrierName !== '' && row.carrierName !== null && row.carrierName !== undefined
                    ? row.carrierName
                    : '- NA -'
            ),
            style: (row: any) => ({
                // cursor: row.carrierName !== '- NA -' ? 'pointer' : 'default',
            }),
            width: '200px',
        },

        {
            name: "SHIPPER",
            selector: row => (
                row.shipperName !== '' && row.shipperName !== null && row.shipperName !== undefined
                    ? row.shipperName
                    : '- NA -'
            ),
            style: (row: any) => ({
                // cursor: row.shipperName !== '- NA -' ? 'pointer' : 'default',
            }),
            width: '220px'
        },
        {
            name: "CONSIGNEE",
            selector: row => (
                row.consingneeName !== '' && row.consingneeName !== null && row.consingneeName !== undefined
                    ? row.consingneeName
                    : '- NA -'
            ),
            style: (row: any) => ({
                // cursor: row.consingneeName !== '- NA -' ? 'pointer' : 'default',
            }),
            width: '220px'
        },
        {
            name: "ORIGIN",
            // selector: (row) => (
            //     <div key={row.origin}>
            //         {row.origin !== '' ? (
            //             <Tooltip title={row.origin} arrow>
            //                 <span>{row.origin}</span>
            //             </Tooltip>
            //         ) : (
            //             <span style={{ fontStyle: 'italic' }}>- NA -</span>
            //         )}
            //     </div>
            // ),
            selector: row => (
                row.origin !== '' && row.origin !== null && row.origin !== undefined
                    ? row.origin
                    : '- NA -'
            ),
            style: (row: any) => ({
                // cursor: row.origin !== '- NA -' ? 'pointer' : 'default',
            }),
            width: '300px',
        },
        {
            name: "DESTINATION",
            // selector: row => row.destination !== '' && row.destination !== null && row.destination != undefined ? <Tooltip title={row.destination} arrow><span>{row.destination}</span></Tooltip> : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            // wrap: false,
            selector: row => (
                row.destination !== '' && row.destination !== null && row.destination !== undefined
                    ? row.destination
                    : '- NA -'
            ),
            style: (row: any) => ({
                // cursor: row.destination !== '- NA -' ? 'pointer' : 'default',
            }),
            width: '300px',
        }

    ]
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        item: any
    ) => {
        setSelectedIndex(index);
        console.log(item, "itemmm")
        setRowData(item.shipmentList)

    };
    useEffect(() => {
        etaDeviationAnalyticsEventsData('all')

    }, [])
    const etaDeviationAnalyticsEventsData = (country: any) => {
        let status: any;
        if (props.pageInfo.tab === "arrivingToday" || props.pageInfo.tab === "Next 2 Hrs") {
            status = {
                dataCode: 0,
                shipmentType: props.pageInfo.shipmentType,
                country: country,
                filterDetails: {
                    filterOn: format ? format : "MTD",
                    startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                    endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
                }

            }

        } else if (props.pageInfo.tab === "arrivingNextDay" || props.pageInfo.tab === ">2 Hrs to <=12 Hrs") {
            status = {
                dataCode: 1,
                shipmentType: props.pageInfo.shipmentType,
                country: country,
                filterDetails: {
                    filterOn: format ? format : "MTD",
                    startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                    endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
                }
            }
        } else if (props.pageInfo.tab === "arrivingNext7Days" || props.pageInfo.tab === ">12 Hrs to <=24 Hrs") {
            status = {
                dataCode: 7,
                shipmentType: props.pageInfo.shipmentType,
                country: country,
                filterDetails: {
                    filterOn: format ? format : "MTD",
                    startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                    endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
                }
            }
        } else {
            status = {
                dataCode: 8,
                shipmentType: props.pageInfo.shipmentType,
                country: country,
                filterDetails: {
                    filterOn: format ? format : "MTD",
                    startDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : "",
                    endDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : "",
                }
            }
        }
        config.params = status
        setLoad(true)
        let destinationList: any = []
        axios.get(Endpoints.Intransit_Shipments_Insights, config).then((res: any) => {
            setLoad(false)
            if (res.data.data.result.length > 0) {
                setShipmentsCount(res.data.data.result)
                setRowData(res.data.data.result[selectedIndex].shipmentList)
                if (country === 'all') {
                    setDestinationCountries(res.data.data.countries)
                }

            }

        })
    }
    const handleChangeDestination = (e: any) => {
        setSelectedItems(e)
        const selectedOptionLabels = e.map((option: any) => option.label);
        console.log(selectedOptionLabels, selectedItems, "selected labels")
        const stringResult = selectedOptionLabels.join(',');

        etaDeviationAnalyticsEventsData(stringResult.length > 0 ? stringResult : 'all')

    }
    const handleData = (params: any) => {
        console.log("Event Screen Console....", props, params);
        setOpenDetail(true)
        setLineData(params);
        setEventData(params)
    };
    const handleDialogClose = () => {
        etaDeviationAnalyticsEventsData('all')
    };
    return (
        <div >
            {
                rowData.length > 0 ?
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <MultiSelect
                            options={destinationCountries.map((label: any) => ({ label, value: label }))}
                            value={selectedItems}
                            onChange={handleChangeDestination}
                            labelledBy="Select By Destination Country"

                            className='multiSelect'
                        />
                        <Paper
                            component="form"
                            elevation={0}
                            className="custom-search"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, float: 'right' }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder={props.pageInfo.shipmentType === 'Ocean' ? "Search By HBL/MBL/Container/Invoice" : "Search By HAWB/MAWB/Invoice"}
                                inputProps={{ 'aria-label': 'search google maps' }}
                                size="small"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" size="small" onClick={handleClear}>
                                {searchTerm === '' ? <SearchIcon /> : <CloseIcon />}
                            </IconButton>
                        </Paper>
                    </div> : ''}
            {
                rowData.length > 0 && !load ?
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: 1 }}>
                                {shipmentsCount.map((item: any, index: any) => (
                                    <List component="nav" aria-label="main mailbox folders">
                                        <ListItemButton
                                            selected={selectedIndex === index}
                                            onClick={(event: any) => handleListItemClick(event, index, item)}
                                        >
                                            <ListItemText primary={item._id.eventName} />({item.count})

                                        </ListItemButton>

                                    </List>
                                ))}
                            </Box>
                            <Divider orientation="vertical" flexItem />
                        </Grid>
                        <Grid item xs={9}>
                            <Box sx={{ mt: 2 }}>
                                {load ? <Loaders /> :
                                    <DataTable
                                        style={{ borderRadius: 3 }}
                                        columns={columns}
                                        data={filterData}
                                        responsive={true}
                                        customStyles={customStyles}
                                        theme="default"
                                        pagination
                                        dense
                                        striped
                                        highlightOnHover={true}
                                        // onRowClicked={(data) => handleData(data)}



                                    />
                                }
                            </Box>

                        </Grid>
                    </Grid> : load && rowData.length === 0 ? <Loaders /> :
                        <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                            No data found
                        </Alert>}

            <div className="dialog">
                {openDetail ? (
                    <IntransitEventsMapping
                        onOpen={openDetail}
                        onClose={() => {
                            setOpenDetail(false);
                            handleDialogClose(); // Call the function when the dialog is closed
                        }}
                        data={EventData}
                        line={lineData}
                        status={"open"}
                        shipmentType={mode}
                        sx={{
                            "& .MuiDialog-root": {
                                borderRadius: "100px",
                                boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
                                zIndex: "9999 !important",
                            },
                        }}
                    />
                ) : null}
            </div>

        </div>
    )
}
