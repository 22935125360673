import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { Endpoints } from '../../../Enviornment/Endpoints';
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7',

        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
            // cursor:'pointer'
        },
    },

};
export default function GateInOutDetails(props: any) {
    console.log(props,"gateinout")

    const columns: TableColumn<any>[] = [
        {
            name: "TRUCK #",
            sortable: true,
            selector: row => row.truckDetails.truckNumber !== '' && row.truckDetails.truckNumber !== null && row.truckDetails.truckNumber !== undefined ? row.truckDetails.truckNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px',
        },

        {
            name: "DRIVER #",
            sortable: true,
            selector: row => row.truckDetails.driverNumber !== '' && row.truckDetails.driverNumber !== null && row.truckDetails.driverNumber !== undefined ? row.truckDetails.driverNumber : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px'
        },
        {
            name: "DRIVER NAME",
            sortable: true,
            selector: row => row.truckDetails.driverName !== '' && row.truckDetails.driverName !== null && row.truckDetails.driverName !== undefined ? row.truckDetails.driverName : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px'
        },
        {
            name: props.event !== 'gateOut' ?"GATE IN DATE":'GATE OUT DATE',
            sortable: true,
            selector: row => row.gateData !== '' && row.gateData
            !== null && row.gateData !== undefined ? props.event !== 'gateOut'? row.gateData
            .gateInDate: row.gateData.gateOutDate : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px'
        },
        {
            name: props.event !== 'gateOut' ?"GATE IN TIME":'GATE OUT TIME',
            sortable: true,
            selector: row => row.gateData !== '' && row.gateData !== null && row.gateData !== undefined ? props.event !== 'gateOut'? row.gateData.gateInTime:row.gateData.gateOutTime : <span style={{ fontStyle: 'italic' }}>- NA -</span>,
            width: '200px'
        },

    ]
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {},
    };
    const [showgrid, setShowGrid] = useState(false)
    const [rowData, setRowData] = useState<any>([])
    const [open, setOpen] = useState(false)
    useEffect(() => {
        gateInOutList()
    }, [])
    const gateInOutList = () => {
        let requestPayload = {
            docReference: props.data.docReference,
            event: props.event
        }
        config.params = requestPayload
        axios.get(Endpoints.gate_In_Out_Report, config).then((res: any) => {
            console.log(res.data, "gatein details")
            if (res.data.primary.length > 0) {
                setRowData(res.data.primary)
                setShowGrid(true)
            }
            // if(res.data)
        })
    }
    const handleClose = () => {
        setOpen(props.onClose)
    }
    return (
        <div>
            <Dialog
                maxWidth="md"
                open={props.onOpen}
                fullWidth={true}
                onClose={props.onClose}
            >
                <DialogTitle>
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "20px",
                            justifyContent: 'space-between'

                        }}
                    >
                        <Typography variant="h6">{props.event !== 'gateOut' ? 'Gate In Truck Details' : 'Gate Out Truck Details'}</Typography>
                        <Typography sx={{ mt: 2 }}>Container #: &nbsp;&nbsp; {props.data?.ContainerNumber}</Typography>
                    </Box>
                </DialogTitle>
                <Box
                    sx={{
                        paddingLeft: "30px",
                        paddingBottom: "30px",
                        paddingRight: "30px",
                    }}
                >
                    {showgrid ? (
                        <DataTable
                            style={{ borderRadius: 3, cursor: 'pointer' }}
                            columns={columns}
                            data={rowData}
                            responsive={true}
                            customStyles={customStyles}
                            theme="default"

                            pagination
                            dense
                            striped
                            highlightOnHover={true}
                        // onRowClicked={(data) => handleData(data)}

                        />
                    ) : (
                        <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                            No data found
                        </Alert>
                    )}
                </Box>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
