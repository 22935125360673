import React, { useEffect, useState } from 'react'

import { makeStyles, Theme } from "@material-ui/core/styles";
import { FormControl, Grid, InputLabel, Link, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { LoadScript } from '@react-google-maps/api';
import { Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import swal from "sweetalert";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from '@mui/material'
import { Endpoints } from '../../../Enviornment/Endpoints';
import { useOutletContext } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import DutyFreeRetailList from './DutyFreeRetailList';
import DutyFreeDashboard from './DutyFreeDashboard';
import AddDutyFreeRetail from './AddDutyFreeRetail';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(0),
        fontSize: "10px",
        // fontWeight: "bold",
        minHeight: "70%",
        "& .MuiDialog-root": {
            borderRadius: "100px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
            zIndex: "9999 !important",
        },
    },
    maincontent: {},
    tab: {
        backgroundColor: "#f1f1f1",
        marginBottom: "10px",
    },
}));
export default function DutyFreeRetailOnboarding() {
    const classes = useStyles();
    const [googleMapsApiKey, setGoogleMapsApiKey] = useState('');
    const [loader, setLoader] = useState(false);
    const [autocomplete, setAutocomplete] = useState(null);
    const [isFormChange, setIsFormChange] = useState(false)
    const [isLatLongEnable, setLatLongEnable] = useState(true)
    const [load, setLoad, mode] = useOutletContext<any>();
    const [tabValue, setTabValue] = React.useState("1");
    const [apiLoaded, setApiLoaded] = useState(false);
    const [place, setPlace] = useState(null);
    const [isScriptLoaded, setScriptLoaded] = useState(false);
    const [alignment, setAlignment] = React.useState('YTD');
    const [filterBy, setFilterBy] = useState("YTD");
    const [reloadFlag, setReloadFlag] = useState(false);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
        // if (newValue === '2' && !reloadFlag) {
        //     setReloadFlag(true);
        //     localStorage.setItem('tabValue', '2');
        //     window.location.reload()
        // }
    };
    const handleBtnChange = (e: any) => {
        setFilterBy(e.target.value)
        
    };
    useEffect(() => {
        setLoad(false);
        console.log(window.location.hash,window.location,"path name")
    }, []);
    return (
        <div className={classes.root}>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider"}}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        // sx={{ '&.MuiTabs-flexContainer': { marginTop: '15px' } }}
                        sx={{ marginTop: '15px !important' }}
                    >
                        <Tab label="Dashboard" value="1" />
                        <Tab label="Add Duty Free Retails" value="2" />
                        <Tab label="Duty Free Ratails List" value="3"/>
                        <FormControl sx={{'&.MuiFormControl-root':{width:'100%',ml:'40%',marginTop:'10px',marginBottom:'5px'}}} >
                            <InputLabel id="demo-simple-select-label">Filter By </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filterBy}
                                size='small'
                                label="Date Filter"
                                onChange={handleBtnChange}
                            >
                                <MenuItem value={'YTD'}>YTD</MenuItem>
                                <MenuItem value={'QTD'}>QTD</MenuItem>
                                <MenuItem value={'MTD'}>MTD</MenuItem>
                            </Select>
                        </FormControl>
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{ padding: '10px 0px' }}>
                    <DutyFreeDashboard filterby={filterBy} />
                </TabPanel>
                <TabPanel value="2" sx={{ padding: '10px 0px' }}>
                    <AddDutyFreeRetail />
                </TabPanel>
                <TabPanel value="3" sx={{ padding: '10px 0px' }}>
                    <DutyFreeRetailList />
                </TabPanel>

            </TabContext>


            <ToastContainer style={{ zIndex: 10000 }} />
        </div>
    )
}
