import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Endpoints } from '../../../Enviornment/Endpoints';
import { GoogleMap, InfoWindow, LoadScript, Marker, MarkerF, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';
import Loaders from '../../common/Loaders';

export default function DutyFreeDashboard(props:any) {
    console.log("Props Are....",props)
    const { control } = useForm();
    const [productName, setProductName] = useState('');
    const [locations, setLocations] = useState<any>()
    const [isShowLocation, setIsShowLocation] = useState(false)
    const [locationCoordinates, setLocationCoordinates] = useState<any>([])
    const [load, setLoad, fromDate, toDate, setFd, setTd] = useOutletContext<any>();
    const [isTooltipShow, setIsTooltipShow] = useState(false)
    const [isMapShow, setIsMapShow] = useState<any>(false)
    const [totalSalesCount, setTotalSalesCount] = useState<any>()
    const [totalInstroreCount,setTotalInstroreCount]=useState<any>()
    const [inStoreCount, setInStoreCount] = useState<any>()
    const [selectedMarker, setSelectedMarker] = useState<any>(null);
    const [productsList, setProductsList] = useState<any>([])
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDhdinMYR_DnkHHUDjyxcDqe4PmT0C7w1Q",
    });
    let icon: any =
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000";
    let iconbetween: any =
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|008000";
    let iconorigin: any =
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|f4c919";
    const iconBase =
        "https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png";
    const mapStyles = {
        height: '400px',
        width: '100%'
    };
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {
            filterOn:props.filterby ||'YTD',
            // fromDate:'',
            // toDate:''

        }
    };
    const getMarkerIcon = () => ({
        path: google.maps.SymbolPath.CIRCLE,
        scale: 10,
        fillColor: '#FF0000', // Red color
        fillOpacity: 1.0,
        strokeWeight: 2,
        strokeColor: '#FFFFFF', // White color
    });
    const mapOptions = {
        zoom: 20,
        styles: [
            {
                "featureType": "all",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        color: '#e2e2e2',
                    }
                ]
            }
        ]
    };
    const dottedLineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 2,
    };
    const handleSelectChange = (e: any) => {
        console.log(e, "event")
        setProductName(e.target.value);
        console.log(config)
        getMapLocation(e.target.value)

    };
    const getMapLocation = async (assertId: any) => {
        setLoad(true)
        await axios.get(Endpoints.Duty_Free_Retail_Instore_Locations + '/' + assertId, config).then((res: any) => {
            setLoad(false)

            let locationsData: any = []
            if (res.data.primary.length > 0) {
                res.data.primary.filter((item: any) => {
                    locationsData.push({ lat: item.lat, lng: item.long, icon: iconBase })
                })
                setLocationCoordinates(locationsData)
                setIsShowLocation(true)
                setLocations(res.data.primary)
                console.log(res.data.primary, "resp")
                setIsMapShow(true)
            }
        })
    }
   
    const getSalesMetrics = async () => {
        config.params = {
            filterOn: props.filterby || "YTD",
            // fromDate: fromDate.$d ? moment(fromDate.$d).format("YYYY-MM-DD") : moment().subtract(2, 'days').format('YYYY-MM-DD'),
            // toDate: toDate.$d ? moment(toDate.$d).format("YYYY-MM-DD") : moment().format('YYYY-MM-DD'),
        }
        await axios.get(Endpoints.Duty_Free_Sales_Count, config).then((res: any) => {
            if (res.data.primary.length > 0) {
                setTotalSalesCount(res.data.primary[0].totalNumberOfSales)
            }
        })
        
       
        await axios.get(Endpoints.Duty_Free_Instore_Count, config).then((res: any) => {
           setLoad(true)
            if (res.data.primary.length > 0) {
              
                setTotalInstroreCount(res.data.primary[0].totalNumberOfInstoreProdcuts)
                console.log("In Store Data...",res.data.primary[0].totalNumberOfInstoreProdcuts)
            }
           
        })
        setLoad(false)
    }
    const handleMarkerClick = (marker: any) => {
        console.log(marker, "marker")
        setSelectedMarker(marker);
        setIsTooltipShow(true)
    };

    const handleInfoWindowClose = () => {
        setSelectedMarker(null);
        setIsTooltipShow(false)
    };
    const getProducts = () => {
        setLoad(true)
        axios.get(Endpoints.Duty_Free_Asserts).then((res: any) => {
            setLoad(false)
            if (res.data.primary.length > 0) {
                setProductsList(res.data.primary)
                getMapLocation(res.data.primary[0]._id.assetID)
                setProductName(res.data.primary[0]._id.assetID)
            }
        })
    }
    useEffect(() => {
        setIsMapShow(false)
        getSalesMetrics()
        getProducts()
    }, []);
    useEffect(()=>{
        getSalesMetrics()
    },[props])
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <InputLabel id="demo-simple-select-label" sx={{ fontSize: '14px', fontWeight: 'bold' }}>Select Product</InputLabel>
                    <FormControl fullWidth size='small' sx={{ width: '300px' }}>
                        <Controller
                            name='productName'
                            control={control}
                            render={({ field }) => (
                                <Select
                                    labelId="demo-simple-select-label"
                                    className='custom-inputs'
                                    {...field}
                                    value={productName}
                                    onChange={handleSelectChange}
                                    MenuProps={{
                                        disableScrollLock: true
                                    }}
                                >
                                    {productsList.length
                                        ? productsList.map((value: any, index: any) => (
                                            <MenuItem value={value._id.assetID} key={index}>
                                                {value._id.assetID}
                                            </MenuItem>
                                        ))
                                        : null}
                                    {/* <MenuItem value='Prod1'>Prod1</MenuItem>
                                    <MenuItem value='Prod2'>Prod2</MenuItem>
                                    <MenuItem value='Prod3'>Prod3</MenuItem> */}
                                </Select>
                            )}
                        />
                    </FormControl>
                    {!load ?
                        <Box sx={{ mt: 2 }}>
                            {isLoaded ?
                                isMapShow ?
                                    <GoogleMap
                                        mapContainerStyle={{ height: "80vh" }}
                                        center={{ lat: locations.length > 0 && locations[0].lat, lng: locations.length > 0 && locations[0].long }}
                                        zoom={mapOptions.zoom}
                                        options={{
                                            styles: mapOptions.styles,
                                            mapId: 'your-map-id',
                                        }}
                                    >
                                        <>
                                            {locations.map((loc: any, index: any) => (
                                                <MarkerF
                                                    key={index}
                                                    position={{
                                                        lat: loc.lat,
                                                        lng: loc.long,
                                                    }}
                                                    icon={icon}
                                                    title={loc.shopName}
                                                    animation={2}
                                                    visible={true}
                                                    onClick={() => handleMarkerClick(index)}
                                                />
                                            ))}
                                            {/* {selectedMarker !== null && (
                                                <InfoWindow
                                                    position={{
                                                        lat: locations[selectedMarker].lat,
                                                        lng: locations[selectedMarker].long,
                                                    }}
                                                    onCloseClick={handleInfoWindowClose}
                                                >
                                                    <div>{locations[selectedMarker].address}</div>
                                                </InfoWindow>
                                            )} */}
                                        </>
                                    </GoogleMap>
                                    : "" : ''}

                        </Box> : <Loaders />}
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '20px', textDecoration: 'underline' }}>SALE METRICS</Typography>
                    <Grid container spacing={2} sx={{ mt: 3.5 }}>
                        <Grid item md={4} lg={6} sm={6} xs={12} className="mainGrid">
                            <Paper elevation={0} className="card">
                                <Box className="card-body" sx={{ backgroundColor: '#3280cd', color: "#fff" }}>
                                    <Typography className="dashboard-title">Total Sales</Typography>
                                    <Typography
                                        variant="h3"
                                        className="dashboard_info"
                                        sx={{ my: 1, fontSize: 30, fontWeight: 700 }}
                                    >
                                         {totalSalesCount==undefined?0:totalSalesCount||0}
                                    </Typography>

                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item md={4} lg={6} sm={6} xs={12} className="mainGrid">
                            <Paper elevation={0} className="card">
                                <Box className="card-body" sx={{ backgroundColor: '#eda411eb', color: '#fff' }}>
                                    <Typography className="dashboard-title">In Store</Typography>
                                    <Typography
                                        variant="h3"
                                        className="dashboard_info"
                                        sx={{ my: 1, fontSize: 30, fontWeight: 700 }}
                                    >
                                        {totalInstroreCount==undefined?0:totalInstroreCount||0}
                                    </Typography>

                                </Box>

                            </Paper>
                        </Grid>
                        <Box>
                            <img src={require('../../../asserts/purfume.jpg')} style={{width:'350px',margin:'0px 130px'  }} />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

        </div>
    )
}
