import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ToastContainer, toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import swal from "sweetalert";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Endpoints } from '../../../Enviornment/Endpoints';
import DataTable, { TableColumn } from 'react-data-table-component';
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7'
        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },

};
export default function ShipmentBulkUpload() {
    const Formref = useRef<any>();
    const [file, setFile] = useState<File>();
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [filename, setFileName] = useState<string>("");
    const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
    const [fileExtError, setFileExtError] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<any>();
    const [ExcelName, setExcelName] = useState<string>("");
    const [rowData, setRowData] = useState<any>([]);
    const [disable, setDisable] = useState<boolean>(true);
    const [isShowFile, setShowFile] = useState(false);
    const [notifyInfo, setNotifyInfo] = useState<any>([])
    let Excel_Api_Config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-date",
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
    }
    let Api_Config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        params: {}
    }
    const columns: TableColumn<any>[] = [
        {
            name: "CONTAINER #",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.containerNumber !== '' && row.containerNumber !== null && row.containerNumber !== undefined) {
                    return <span>{row.containerNumber}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "MAWB #",
            width: '200px',
            sortable: true,
            omit: mode == 'Ocean' ? true : false,
            cell: (row: any) => {
                if (row.awbNumber !== '' && row.awbNumber !== null && row.awbNumber !== undefined) {
                    return <span>{row.awbNumber}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "VALIDATION MESSAGE",
            sortable: true,
            cell: (row: any) => {
                if (row.errorStatus === true) {
                    return (
                        <p style={
                            {
                                width: row.errorStatus === true ? '500px' : '200px'
                            }
                        }>
                            <CancelIcon sx={{ fontSize: "18px", color: "red" }} />{" "}
                            {row.error}
                        </p>
                    );
                } else {
                    return <p>
                        <CheckCircleIcon
                            sx={{ fontSize: "18px", color: "green", lineHeight: 0 }}
                        />{" "}
                        VALID
                    </p>;
                }
            }

        },
        {
            name: "MBL #",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.mblNumber !== '' && row.mblNumber !== null && row.mblNumber !== undefined) {
                    return <span>{row.mblNumber}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "INVOICE #",
            width: '200px',
            sortable: true,
            cell: (row: any) => {
                if (row.invoiceNumber !== '' && row.invoiceNumber !== null && row.invoiceNumber !== undefined) {
                    return <span>{row.invoiceNumber}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "INCOTERMS",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.incoTerms !== '' && row.incoTerms !== null && row.incoTerms !== undefined) {
                    return <span>{row.incoTerms}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "CARRIER",
            sortable: true,
            omit: mode == 'Air' ? true : false,
            width: mode == 'Air' ? '200px' : '200px',
            cell: (row: any) => {
                if (row.carrierName !== '' && row.carrierName !== null && row.carrierName !== undefined) {
                    return <span>{row.carrierName}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "SHIPPER",
            width: '200px',
            sortable: true,
            cell: (row: any) => {
                if (row.shipperName !== '' && row.shipperName !== null && row.shipperName !== undefined) {
                    return <span>{row.shipperName}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "CONSIGNEE",
            width: '200px',
            sortable: true,
            cell: (row: any) => {
                if (row.consigneeName !== '' && row.consigneeName !== null && row.consigneeName !== undefined) {
                    return <span>{row.consigneeName}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "NOTIFY INFO",
            sortable: true,
            width: '200px',
            cell: (row) => (
                <>

                    <IconButton
                        sx={{ padding: "0", margin: "0", fontSize: '12px' }}
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                        // disabled={row.errorStatus === false ? false : true}
                        onClick={() => notificationInfo(row)}
                    >
                        NOTIFY ME
                    </IconButton>
                </>
            ),
        },

    ]
    const columns1: TableColumn<any>[] = [
        {
            name: "S.NO",
            width: '100px',
            cell: (row: any, index: number) => {
                return <span>{row.serialNumber}</span>
            }
        },
        {
            name: "NAME",
            width: '200px',
            sortable: true,
            // omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.identifier !== '' && row.identifier !== null && row.identifier !== undefined) {
                    return <span>{row.identifier}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "EMAIL",
            width: '200px',
            sortable: true,
            // omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.email !== '' && row.email !== null && row.email !== undefined) {
                    return <span>{row.email}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
    ]
    function filevalidation(e: ChangeEvent<HTMLInputElement>) {
        if (e.target.files) {
            let split = e.target.files[0].name.split(".");
            console.log(e.target.files[0], "files");
            if (split[1] !== "xlsx") {
                console.log(split);
                setFileExtError(true);
                toast.error("Invalid File Format,Please Upload XLSX Files");
            } else {
                setFileExtError(false);
            }
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
            setExcelName(e.target.files[0].name);
        }
    }
    const Preview = () => {
        if (file === undefined) {
            toast.warning("Please Select a File...");
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        setLoad(true)
        axios.post(Endpoints.Shipment_Registration_Request_Preview, formData, Excel_Api_Config).then((res: any) => {
            setLoad(false)
            setPreviewData(res.data);
            setRowData(res.data.data);

            res.data.data.map((disables: any) => {
                console.log(disables, "respo")
                if (disables.errorStatus === false) {
                    setDisable(false);
                } else {
                    setDisable(true);
                }
            });
        }).catch((e: any) => {
            setLoad(false);
        });
        setShowFile(true);
    }
    const SubmitFile = () => {
        let submitPayload: any = {
            additionalInfo: previewData.data
        };
        console.log(submitPayload, "submit")
        setLoad(true)
        axios.post(Endpoints.Create_Shipment_Registration, submitPayload, Api_Config).then((res: any) => {
            setLoad(false)
            if (res.data.primary.status === 'SUCCESS') {
                toast.success('Shipment registered successfully and is currently being processed.')
            } else {
                if (res.data.primary.status === 'FAILED') {
                    swal('Warning', res.data.status.message, 'warning')
                }
            }
        }).catch((err: any) => {
            setLoad(false)
            swal('Warning', 'The shipment you are trying to register already exists with the same combination.', 'warning')
        });
        setRowData([]);
        setShowFile(false);
        Formref.current.reset();
        setExcelName("Selcet File");
    };
    const reset = () => {
        setRowData([]);
        setShowFile(false);
        Formref.current.reset();
        setExcelName("Selcet File");
        setFile(undefined);
    }
    const closeAlert = () => setAlertOpen(false);
    const notificationInfo = (row: any) => {
        console.log(row.notify, "row")
        const responseDataWithSerialNumber = row.notify.map((data: any, index: any) => ({
            ...data,
            serialNumber: index + 1,
            containerNumber: row.containerNumber
        }));
        console.log(responseDataWithSerialNumber)
        setNotifyInfo(responseDataWithSerialNumber)
        setAlertOpen(true)
    }
    useEffect(() => {
        setLoad(false)
        setRowData([]);
        setShowFile(false);
        Formref.current.reset();
        setExcelName("Selcet File");
        setFile(undefined);
    }, [fromDate, toDate, format, mode])
    return (
        <div>
            <Typography
                sx={{
                    color: "black",
                    flexDirection: "end",
                    display: "flex",
                    alignItems: "center",
                    mt: 2
                }}
            >
                <a
                    href={process.env.PUBLIC_URL + mode === 'Ocean' ? "/ShipmentOceanUpload.xlsx" : '/ShipmentAirUpload.xlsx'}
                    download={"shipmentRegistration.xlsx"}
                    className="textFile"
                >
                    <IconButton sx={{ padding: 0 }}>
                        <FileDownloadIcon />
                    </IconButton>{" "}
                    Download Sample Template
                </a>
            </Typography>
            <Box
                className="fileupload"
                sx={{
                    display: "flex",
                    flexDirection: "row",

                    alignItems: "center",
                    mt: 2,
                }}
            >
                <Box
                    sx={{
                        marginRight: "5px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <form ref={Formref}>
                        <TextField
                            type="file"
                            placeholder="Choose file"
                            name="Choose File"
                            className="file"
                            onChange={filevalidation}
                            sx={{
                                "& .MuiInputBase-input": {
                                    padding: "8px !important",
                                },
                            }}
                        />
                    </form>
                    {fileExtError ? (
                        <span className="rstatus">*INVALID FILE FORMAT</span>
                    ) : null}
                </Box>
                <Box>
                    <LoadingButton
                        loading={false}
                        variant="contained"
                        type="submit"
                        sx={{
                            justifyContent: "end",
                            fontWeight: 500,
                            marginLeft: "inherit",
                        }}
                        onClick={(event) => Preview()}
                    >
                        Preview
                    </LoadingButton>
                    <span>&nbsp; &nbsp;</span>

                    <LoadingButton
                        loading={false}
                        variant="contained"
                        type="submit"
                        className="submitbtn"
                        sx={{
                            justifyContent: "end",
                            fontWeight: 500,
                            ml: 2,
                            backgroundColor: "#555a64",
                            color: "#fff",
                            "& .MuiButtonBase-root:hover": {
                                backgroundColor: "#555a64",
                                color: "#fff",
                            },
                        }}
                        onClick={(event) => reset()}
                    >
                        Reset
                    </LoadingButton>
                </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
                {rowData.length > 0 ? (
                    <Box>
                        <Paper elevation={3} sx={{ borderRadius: 3, mt: 2 }}>
                            <Box sx={{ p: 0, mt: 1 }}>
                                <DataTable
                                    style={{ borderRadius: 3 }}
                                    columns={columns}
                                    data={rowData}
                                    responsive={true}
                                    customStyles={customStyles}
                                    theme="default"
                                    pagination
                                    dense
                                    striped
                                    highlightOnHover={true}

                                />
                            </Box>
                        </Paper>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "left",
                                marginTop: "10px",
                            }}
                        >
                            {!disable ? (
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="success"
                                    onClick={SubmitFile}
                                >
                                    Submit
                                </Button>
                            ) : (
                                <Button variant="contained" type="submit" disabled>
                                    Submit
                                </Button>
                            )}
                        </Box>
                    </Box>

                )
                    : (
                        <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                            No data found
                        </Alert>
                    )
                }
            </Box>
            <ToastContainer />
            <Dialog
                open={alertOpen}
                // TransitionComponent={Transition}
                keepMounted
                onClose={closeAlert}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"NOTIFICATION INFO"} &nbsp; - &nbsp; {notifyInfo[0]?.containerNumber}</DialogTitle>
                <DialogContent>
                    <DataTable
                        style={{ borderRadius: 3 }}
                        columns={columns1}
                        data={notifyInfo}
                        responsive={true}
                        customStyles={customStyles}
                        theme="default"
                        pagination
                        dense
                        striped
                        highlightOnHover={true}

                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAlert}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
