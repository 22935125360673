import {
    TimePicker,
    DatePicker,
    LocalizationProvider,
    DesktopDatePicker,
} from "@mui/x-date-pickers";
import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { Endpoints } from "../../../Enviornment/Endpoints";
import moment from "moment";

export default function WareHouseUpdate(props: any) {
    const [open, setOpen] = useState(false);
    const [phcode, setPhoneCode] = useState<any>('')
    const [isSelected2, setIsSelected2] = useState(false);
    const [updateDate, setUpdateDate] = React.useState<Dayjs | any>(null);
    const [updateTime, setUpdateTime] = React.useState<Dayjs | any>(null);
    const [phoneCode, setPhoneCodes] = useState<any | null>()
    const [maxDate,setMaxDate] = React.useState<Dayjs | any>(moment().format('YYYY-MM-DD'));
    const { register, resetField, reset, setValue, formState: { errors }, control, handleSubmit, watch } = useForm({
        defaultValues: {
            truckNumber: '',
            driverName: '',
            driverNumber: '',
            date:'',
            time:''
        },
    });
    const iconAdornment2 = isSelected2
        ? {
            startAdornment: (
                <InputAdornment position="start">
                    {phoneCode}
                </InputAdornment>
            )
        }
        : {};
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {

        }
    };
    const handleClose = () => {
        setOpen(props.onClose)
    }
    const handleUpdateDate = (newValue: Dayjs | any) => {
        
        let data = newValue.$d;
        // console.log(date);
        var responseDate = moment(data).format("DD-MMM-YYYY");
        console.log(responseDate,"date");
        setUpdateDate(responseDate);
        setValue(`date`,responseDate)
    };

    const handleUpdateTime = (newValue: Dayjs | any) => {
        setUpdateTime(newValue)
         setValue(`time`,moment(newValue.$d).format("HH:mm"))
    };
    const createData = (data: any) => {
        let requestPayload = {
            doc_reference: props.data.docReference,
            shipmentStatus: props.shipmentStatus
        }
        config.params = requestPayload
        console.log(data, "date info")
        axios.post(Endpoints.update_wh_Details, data, config).then((res: any) => {
            console.log(res.data, "update inward details")
            handleClose()
            props.parentCallback(data)
        })
    }
    return (
        <div>
            <Dialog
                maxWidth="md"
                open={props.onOpen}
                fullWidth={true}
                onClose={props.onClose}
            >
                <DialogTitle>
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "20px",
                            justifyContent: 'space-between'

                        }}
                    >
                        <Typography variant="h6">Truck Details</Typography>
                        <Typography sx={{ mt: 2 }}>Container #: &nbsp;&nbsp; {props.data?.ContainerNumber}</Typography>
                    </Box>
                </DialogTitle>
                <Box
                    sx={{
                        paddingLeft: "30px",
                        paddingBottom: "30px",
                        paddingRight: "30px",
                    }}
                >
                    <form onSubmit={handleSubmit((data: any) => {
                        createData(data);
                    })}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Box sx={{ marginRight: "10px" }}>
                                    <InputLabel id="truck-number"> Truck Number</InputLabel>
                                    <TextField
                                        {...register('truckNumber')}
                                        id="outlined-basic"
                                        fullWidth
                                        size='small'
                                        className='custom-inputs'
                                        variant="outlined"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{ display: 'flex', flexDirection: 'column',color:'#ddd' }}>
                                    <InputLabel id="phone-number">Driver Number</InputLabel>
                                    <TextField
                                        size="small"
                                        {...register('driverNumber', {
                                            // required: 'Phone number is required.',
                                            pattern: {
                                                value: /^\+?[0-9]{8,14}$/,
                                                message: 'Enter a valid phone number.'
                                            }
                                        })}
                                        id="outlined-basic"
                                        variant="outlined"
                                        disabled
                                        sx={{backgroundColor:'#ddd'}}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">+91</InputAdornment>
                                        }}
                                        error={Boolean(errors.driverNumber)}
                                        helperText={errors.driverNumber?.message}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel
                                    htmlFor="outlined-basic"
                                >
                                    Driver Name
                                </InputLabel>
                                <TextField
                                    {...register(`driverName`)}
                                    id="outlined-basic"
                                    type="text"
                                    fullWidth
                                    size='small'
                                    className='custom-inputs'
                                    disabled
                                    sx={{backgroundColor:'#ddd'}}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{ marginBottom: "15px" }}>
                                    <InputLabel id="truck-number"> {props.shipmentStatus === 'whGateOut' ? 'Gate Out Date' : 'Gate In Date'}&nbsp;<span style={{ color: 'red' }}>*</span></InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            inputFormat="DD/MM/YYYY"
                                            value={updateDate}
                                            onChange={handleUpdateDate}
                                            minDate={props.shipmentStatus === 'whGateOut' && props.data.gateInOrOutDetails.gateInDate}
                                            maxDate={maxDate}
                                            renderInput={(params: any) => (
                                                <TextField {...params} required size="small" fullWidth />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{ marginBottom: "15px" }}>
                                    <InputLabel id="truck-number">{props.shipmentStatus === 'whGateOut' ? 'Gate Out Time' : 'Gate In Time'}&nbsp;<span style={{ color: 'red' }}>*</span> </InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker

                                            value={updateTime}
                                            onChange={handleUpdateTime}
                                            renderInput={(params: any) => (
                                                <TextField {...params} required size="small" fullWidth />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Box>

                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button variant="contained" type="submit" size="small" >Update</Button>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </form>

                </Box>
            </Dialog>

        </div>
    )
}
