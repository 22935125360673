import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  TextField,
  Typography,
  Toolbar,
  AppBar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Paper,
  styled,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DateTimePicker,
  DatePicker,
  LocalizationProvider,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import React, { CSSProperties, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import sizeConfigs from "../../Config/sizeConfigs";
import colorConfigs from "../../Config/colorConfig";
import { Bars } from "react-loader-spinner";
import swal from "sweetalert";
import CustomSwitch from "./CustomSwitch";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
function SubH(props: any) {
  const location = useLocation();
  const [age, setAge] = React.useState('');
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [fromDate, setFd] = React.useState<Dayjs | any>(moment().subtract(2, 'days').format('YYYY-MM-DD'));
  const [toDate, setTd] = React.useState<Dayjs | any>(moment().format('YYYY-MM-DD'));
  const [format, setFormat] = useState<any>("MTD");
  const [shipmentMode, setShipmentMode] = useState('Ocean')
  const [filterOpen, SetFilterOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#ffffff");
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [fromCurrentDate, setFromCurrentDate] = React.useState<Dayjs | any>(moment().format('YYYY-MM-DD'));
  const [toCurrentDate, setToCurrentDate] = React.useState<Dayjs | any>(moment().format('YYYY-MM-DD'));
  const handleSwitchChange = (event: SelectChangeEvent) => {
    setChecked(!checked);
    console.log(!checked ? 'Air' : 'Ocean', "valueeeeeee")
    setShipmentMode(!checked ? 'Air' : 'Ocean')
    handleChangeShipmentMode(!checked ? 'Air' : 'Ocean')
  };
  const handleChangeShipmentMode = (shipment: any) => {
    console.log(shipment, "shipment")
    props.getShipmentMode(shipment);
  }
  useEffect(() => {
    console.log("SUB HEADER CALLING.....................", props, fromDate);
    setFd(moment().subtract(2, 'days').format('YYYY-MM-DD'));
    setTd(moment().format('YYYY-MM-DD'));
    setFormat("");
    props.getdates("", "", "");
    if (props.location === '/upload_purchase_order') {
      setLoading(false)
    } else {
      setLoading(props.loader)
    }
  }, [location]);
  const handleChanges = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    onSubmitForCustomDates(event.target.value)
    setFormat(event.target.value);
  };
  const handlefd = (newValue: Dayjs | any) => {
    setFd(newValue);
    // let data = newValue.$d
    // var responseDate = moment(data).format("DD-MM-YYYY");
    // let date2 = toDate.$d;
    // var resp2 = moment(date2).format('DD-MM-YYYY')
    // console.log(responseDate,resp2,"todateeeee")
    // if(responseDate > resp2){
    //   setToCurrentDate(toDate)
    // }
  };
  const handletd = (newValue: Dayjs | any) => {
    // setTd(newValue);
    // let data = newValue.$d
    // var responseDate = moment(data).format("DD-MM-YYYY");
    // let date2 = fromDate.$d;
    // var resp2 = moment(date2).format('DD-MM-YYYY')

    // if(responseDate < resp2){
    //   setFromCurrentDate(responseDate)
    //   console.log(responseDate,resp2,"todateeeee")
    // }

    if (newValue < fromDate) {
      // If the selected "To Date" is before the "From Date," prevent changing "From Date."
      return;
    }
    setTd(newValue);
  };
  const onSubmit = () => {
    if (fromDate === null && toDate === null) {
      swal("info", "Please Select From Date And To Date", "info");
    } else if (fromDate === null) {
      swal("info", "Please Select From Date", "info");
    } else if (toDate === null) {
      swal("info", "Please Select To Date", "info");
    } else {
      props.getdates(fromDate, toDate, format);
    }
  };
  const onSubmitForCustomDates = (format: any) => {
    console.log("Clicked on format ..", format)
    props.getdates("", "", format);
    setFd(null);
    setTd(null);
  };
  const Reset = () => {
    props.getdates("", "");
    setFd(moment().subtract(2, 'days').format('YYYY-MM-DD'));
    setTd(moment().format('YYYY-MM-DD'));
  };
  const handleFilters = () => SetFilterOpen(!filterOpen);
  const handleSubmit = () => { }
  const handleetd = () => { }
  const handleChange = () => { }
  return (
    <Box
      sx={{
        justifyContent: "space-between",
        color: colorConfigs.topbar.color,
        display: "flex",
      }}
    >
      <Box
        sx={{
          // justifyContent: "space-between",
          color: colorConfigs.topbar.color,
          display: "flex",
        }}
      >
        <Typography
          sx={{
            textTransform: "capitalize",
            fontSize: sizeConfigs.pageTitle.fontSize,
            letterSpacing: sizeConfigs.pageTitle.letterSpacing,
            color: colorConfigs.pageTitle.color,
            fontWeight: sizeConfigs.pageTitle.fontWeight,
            marginRight: location.pathname !== '/sea_rates' && location.pathname !== '/registrations' && location.pathname !== '/shipmentRegistrations' && location.pathname !== '/registred_Shipments' && location.pathname !== '/digitalThread' ? "15px" : '0px',
          }}
        >
          {location.pathname === "/"
            ? "Dashboard"
            : location.pathname !== '/sea_rates' && location.pathname !== '/registrations' && location.pathname !== '/shipmentRegistrations' && location.pathname !== '/digitalThread' && location.pathname !== '/duty_free_retail_onboard'? location.pathname.slice(1).replaceAll("_", " ") : ''}

        </Typography>
        {location.pathname !== '/sea_rates' && location.pathname !== '/registrations' && location.pathname !== '/shipmentRegistrations' && location.pathname !== '/digitalThread' && location.pathname !== '/shipment_preview' && location.pathname !== '/duty_free_retail_onboard'  ? <Bars
          height="30"
          width="30"
          color="#2a76f4"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={props.loader}
        /> : ''}

      </Box>

      {location.pathname === "/" ? (
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {location.pathname === '/' ? <Typography className="link">
            <Button variant="contained" size="small" onClick={() => navigate('/shipmentRegistrations')}>Register To Track</Button>
          </Typography> : ''}&nbsp;&nbsp;&nbsp;
          <Typography
            sx={{ fontSize: "0.85rem", fontWeight: "400", fontFamily: "roboto" }}
          >
            FILTER BY REGISTERED DATES: &nbsp;
          </Typography>

          <FormControl
            size="small"
            sx={{ width: "150px", marginRight: "15px" }}
          >
            <InputLabel id="demo-simple-select-label">
              {format ? format : "MTD"}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={format}
              label="Format"
              defaultValue="MTD"
              onChange={handleChanges}
              MenuProps={{ disableScrollLock: true }}

            >
              <MenuItem value={"YTD"}>YTD</MenuItem>
              <MenuItem value={"QTD"}>QTD</MenuItem>
              <MenuItem value={"MTD"}>MTD</MenuItem>
              <MenuItem value={"CUSTOM"} onChange={() => onSubmit()}>CUSTOM</MenuItem>
            </Select>
          </FormControl>

          {format === "CUSTOM" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box sx={{ marginRight: "15px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="From Date"
                    inputFormat="DD/MM/YYYY"
                    value={fromDate}
                    onChange={handlefd}
                    maxDate={fromCurrentDate}
                    renderInput={(params: any) => (
                      <TextField {...params} required size="small" />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ marginRight: "15px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="To Date"
                    inputFormat="DD/MM/YYYY"
                    value={toDate}
                    onChange={handletd}
                    minDate={fromDate}
                    maxDate={toCurrentDate}
                    renderInput={(params: any) => (
                      <TextField {...params} required size="small" />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          ) : null}
          {
            format === "CUSTOM" &&
            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              color="success"
              onClick={() => onSubmit()}
            >
              Search
            </Button>
          }
        </Box>
      ) : null}
      {location.pathname === "/" || location.pathname.includes('request') ||
        location.pathname.includes("upload") ? null : location.pathname !== '/sea_rates' && location.pathname !== '/shipment_preview' && location.pathname !== '/registrations' && location.pathname !== '/shipmentRegistrations' && location.pathname !== '/registred_Shipments' && location.pathname !== '/digitalThread' && location.pathname !== '/CarrierWiseList' && location.pathname !== '/supported_carrier_list' && location.pathname !== '/duty_free_retail_onboard'? (
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              sx={{ fontSize: "1.0rem", fontWeight: "400", fontFamily: "roboto" }}
            >
              FILTER BY REGISTERED DATES: &nbsp;
            </Typography>
            <Box sx={{ marginRight: "15px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="From Date"
                  inputFormat="DD/MM/YYYY"
                  value={fromDate}
                  onChange={handlefd}
                  disableFuture={true}
                  maxDate={toDate}
                  renderInput={(params: any) => (
                    <TextField {...params} required size="small" />
                  )}
                />
              </LocalizationProvider>

            </Box>
            <Box sx={{ marginRight: "15px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="To Date"
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={handletd}
                  // disablePast={true}
                  disableFuture={true}
                  renderInput={(params: any) => (
                    <TextField {...params} required size="small" />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              color="success"
              onClick={() => onSubmit()}
            >
              Search
            </Button>
            <Button variant="contained" color="inherit" onClick={() => Reset()}>
              Reset
            </Button>
          </Box>
        ) : ''}
      {/* {location.pathname === '/sea_rates' ? (
        
      ) : ''} */}

      {/* <Drawer
        anchor={"right"}
        open={filterOpen}
        onClose={handleFilters}
        sx={{
          "& .MuiDrawer-paper": {
            width: "500px !important",
          },
        }}
      >
        <Box>
          <Formik
            initialValues={{
              email: "",
              color: "red",
              firstName: "",
              lastName: "",
            }}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
              }, 1000);
            }}
          >
            {(props: FormikProps<any>) => (
              <Form>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: "14px",
                    marginTop: 2,
                    marginLeft: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      marginRight: "10px",
                    }}
                  >
                    <Typography sx={{ color: "black" }}>
                      APPLY FILTERS{" "}
                      <IconButton>
                        <FilterAltIcon />
                      </IconButton>
                    </Typography>{" "}
                   
                    <IconButton>
                      <CloseIcon onClick={handleFilters} />
                    </IconButton>
                  </Box>
                </Box>
                <Grid item xs={12} sx={{ display: "flex", margin: 2 }}>
                  <Grid item xs={6} sx={{ margin: 1 }}>
                    <label>From Date :</label>
                    <TextField
                      name="Fromdate"
                      id="outlined-helper-text"
                      type="date"
                      sx={{
                        width: "100%",
                        marginTop: "2px",
                      }}
                    />
                  </Grid>
                  <Grid sx={{ margin: 1 }}>
                    <label>To Date :</label>
                    <TextField
                      name="Fromdate"
                      id="outlined-helper-text"
                      type="date"
                      sx={{
                        marginTop: "2px",
                        width: "100%",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    marginLeft: 3,
                    justifyContent: "start",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{
                      p: 1.2,
                      marginBottom: 5,
                      fontWeight: 500,
                      width: "150px",
                    }}
                    className="submitbtn"
                  >
                    SUBMIT
                  </LoadingButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Drawer> */}
    </Box>

    // </Box>
  );
}

export const SubHeader = React.memo(SubH);
