import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import Shop2SharpIcon from "@mui/icons-material/Shop2Sharp";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import TransferWithinAStationSharpIcon from "@mui/icons-material/TransferWithinAStationSharp";
import WarehouseSharpIcon from "@mui/icons-material/WarehouseSharp";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ShortcutIcon from '@mui/icons-material/Shortcut';
import PartnerOnboarding from "../components/pages/Registrations/PartnerOnboarding";
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import FastForwardIcon from "@mui/icons-material/FastForward";
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
const TabData = [
  {
    id: 0,
    parentnav: "Dashboard",
    icon: <DashboardSharpIcon />,
    // children: [],
    access: true,
    route: "/dashboard",
  },
  {
    id: 1,
    parentnav: "PO's",
    icon: <StickyNote2OutlinedIcon />,
    route: "/purchase",
    children: [
      {
        cid: 0,
        title: "PURCHASE ORDER", 
        icon: <StickyNote2OutlinedIcon />,
        disable:true,
      },
      {
        cid: 1,
        childnav: "Pending Purchase Order",
        route: "/pending_purchase_order",
        disable:true,
       
      },
      // {
      //   cid: 2,
      //   childnav: "Upload Purchase Order",
      //   route: "/purchase/uploadpo",
      //   access: true,
      // },

      {
        cid: 3,
        childnav: "Accepted Purchase Order ",
        route: "/accepted_purchse_order",
        disable:true,
        
      },
      {
        cid: 4,
        childnav: "Rejected Purchase Order ",
        route: "/rejected_purchase_order",
        disable:true,
       
      },
    ],
    access: true,
  },
  {
    id: 2,
    parentnav: "Shipments",
    icon: <LocalShippingSharpIcon />,
    children: [
      {
        cid: 0,
        title: "FORWARDER",
        icon: <FastForwardIcon />,
        disable:true,
      },
      {
        cid: 7,
        childnav: "Booking Request",
        route: "/booking_request",
        access: true,
        disable:true,
      },
      {
        cid: 1,
        childnav: "Pending Forwarder Confirmation",
        route: "/pending_forwarder_confirmation",
        access: true,
        disable:false,
      },
      {
        cid: 2,
        childnav: "Confirmed By Forwarder",
        route: "/confirmed_by_forwarder",
        access: true,
        disable:true,
      },

      {
        cid: 3,
        childnav: "Rejected By Forwarder",
        route: "/rejected_by_forwarder",
        access: true,
        disable:true,
      },
      {
        cid: 4,
        title: "CARRIER",
        icon: <DirectionsBoatFilledOutlinedIcon />,
      },
      {
        cid: 4,
        childnav: "Pending Carrier Confirmation",
        route: "/pending_carrier_confirmation",
        access: true,
        disable:true,
      },
      {
        cid: 5,
        childnav: "Confirmed By Carrier",
        route: "/confirmed_by_carrier",
        access: true,
        disable:true,
      },
      {
        cid: 6,
        childnav: "Rejected By Carrier",
        route: "/rejected_by_carrier",
        access: true,
        disable:true,
      },
    ],
    access: true,
  },
  {
    id: 3,
    parentnav: "InTransit ",
    icon: <TransferWithinAStationSharpIcon />,
    children: [
      {
        cid: 0,
        title: "INTRANSIT",
        icon: <Shop2SharpIcon />,
      },
      {
        cid: 1,
        childnav: "InTransit Events Upload",
        route: "/intransit_events_upload",
        disable:true,
        access: true,
      },
      {
        cid: 2,
        childnav: "InTransit Shipments",
        route: "/intransit_shipments",
        access: true,
      },
    ],
    access: true,
  },
  {
    id: 4,
    parentnav: "Delivered Report",
    icon: <AllInboxIcon />,
    route: "/delivered_report",
    access: false,
  },
  {
    id: 5,
    parentnav: "WareHouse",
    icon: <WarehouseSharpIcon />,
    // children: [],
    access: true,
    disable:true,
  },
  {
    id: 6,
    parentnav: "Returns",
    icon: <SwapHorizIcon />,
    children: [
      {
        cid: 0,
        title: "RETURN ORDERS",
        icon: <SwapHorizIcon />,
        disable:true,
      },
      {
        childnav: "Pending Return Orders Confirmation",
        route: "/pending_return_orders_confirmation",
        access: true,
        disable:true,
       
      },
      {
        childnav: "Confirmed Return Orders ",
        route: "/confirmed_return_orders",
        access: true,
        disable:true,
      },
      {
        childnav: "Rejected Return Order ",
        route: "/rejected_return_order",
        access: true,
        disable:true,
      },
    ],
    access: true,
  },
  {
    id: 7,
    parentnav: "Registrations",
    icon: <HowToRegOutlinedIcon />,
    children: [
      {
        cid: 0,
        title: "REGISTRATIONS",
        icon: <HowToRegOutlinedIcon />,
      },
      // process.env.REACT_APP_ENV != 'multitenancy' ?
      {
        cid: 1,
        childnav: "Partner Onboarding",
        route: "/registrations",
        access: true,
        disable:true,
      },
      // : '',
      {
        cid: 2,
        childnav: "Shipment Registrations",
        route: "/shipmentRegistrations",
        access: true,
      },
      process.env.REACT_APP_ENV != 'multitenancy' ?
      {
        cid: 3,
        childnav: "Registred Shipments",
        route: "/registred_Shipments",
        access: true,
        disable:true
      }:''
    ],
    access: true,
  },
  {
    id: 8,
    parentnav: "digitalThread",
    icon: <WarehouseSharpIcon />,
    // children: [],
    access: true,
  },
  {
    id: 9,
    parentnav: "Instore",
    icon: <StorefrontOutlinedIcon />,
    access: true,
  },
  
];

export default TabData;
