import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataTable, { TableColumn } from "react-data-table-component";
import { LoadingButton } from "@mui/lab";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import swal from "sweetalert";
import { Endpoints } from "../../../Enviornment/Endpoints";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from 'react-redux';
import { resetShipmentData, setShipmentData } from "../../../Redux/Slices/ShipmentRegistrationSlice";
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7'
        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },

};
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        // padding: theme.spacing(2),
        fontSize: "10px",
        borderRadius: '10px 0px 0px 10px',
        // fontWeight: "bold",
        minHeight: "100%",
        "& .MuiDialog-root": {
            borderRadius: "100px",
            boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
            zIndex: "9999 !important",
        },
    },
    maincontent: {},
    tab: {
        backgroundColor: "#f1f1f1",
        marginBottom: "10px",
    },
}));
export default function ShipmentRegistrationPreview(props: any) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const shipmentData = useSelector((state: any) => state.shipment);
    const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
    const [notifyData, setNotifyData] = useState<any>([]);
    const [shipmentInfoData, setShipmentInfoData] = useState<any>([])
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    console.log(shipmentData, "propssss")
    let shipmentPreview: any;
    if (shipmentData.additionalInfo[0].shipmentMode === 'INTERMODAL_SHIPMENT') {
        shipmentPreview = [{
            title: 'Shipment Info',
            shipmentInfo: [
                { header: 'Carrier', field: shipmentData.additionalInfo[0].carrierName.carrierName },
                { header: 'Container #', field: shipmentData.additionalInfo[0].containerNumber },
                { header: 'MBL #', field: shipmentData.additionalInfo[0].mblNumber },
                { header: 'Invoice #', field: shipmentData.additionalInfo[0].invoiceNumber },
                { header: 'IncoTerms', field: shipmentData.additionalInfo[0].incoTerms.incotermName },
                { header: 'Shipper', field: shipmentData.additionalInfo[0].shipperName },
                { header: 'Consignee', field: shipmentData.additionalInfo[0].consigneeName },
                { header: 'WareHouse', field: shipmentData.additionalInfo[0].warehouseId },
                { header: 'Scheduled Delivery Date', field: shipmentData.additionalInfo[0].scheduledDate },
            ]
        },
        {
            title: 'Additional Info',
            additionalInfo: [
                { header: 'Booking #', field: shipmentData.additionalInfo[0]?.bookingNumber || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span> },
                { header: 'Shipment Type', field: shipmentData.additionalInfo[0]?.mode || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'Item Name', field: shipmentData.additionalInfo[0]?.itemName || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'Item Quantity', field: shipmentData.additionalInfo[0]?.itemQuantity || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'UOM', field: shipmentData.additionalInfo[0]?.UOM || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'HBL #', field: shipmentData.additionalInfo[0]?.hblNumber || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span> },
                { header: 'Shipment Reference #', field: shipmentData.additionalInfo[0]?.shipmentReferenceNo || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'Promised ETA', field: shipmentData.additionalInfo[0]?.actualETA || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'Total Package', field: shipmentData.additionalInfo[0]?.totalPack || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'Package UOM', field: shipmentData.additionalInfo[0]?.packUOM || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'Total Weight', field: shipmentData.additionalInfo[0]?.totalWeight || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'Weight UOM', field: shipmentData.additionalInfo[0]?.weightUOM || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'Total Volume', field: shipmentData.additionalInfo[0]?.totalVolume || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
                { header: 'Volume UOM', field: shipmentData.additionalInfo[0]?.volumeUOM || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},
            ]
        },
        {
            title: 'Notification Info',
            additionalInfo: []
        }
        ]
    } else if (shipmentData.additionalInfo[0].shipmentMode === 'AIR_SHIPMENT') {
        shipmentPreview = [{
            title: 'Air Info',
            shipmentInfo: [
                { header: 'MAWB #', field: shipmentData.additionalInfo[0].awbNumber },
                { header: 'Carrier', field: shipmentData.additionalInfo[0].carrierName },
                { header: 'Invoice #', field: shipmentData.additionalInfo[0].invoiceNumber },
                { header: 'Shipper', field: shipmentData.additionalInfo[0].shipperName },
                { header: 'Consignee', field: shipmentData.additionalInfo[0].consigneeName },
            ]
        },
        {
            title: 'Additional Info',
            additionalInfo: [

                { header: 'Shipment Reference #', field: shipmentData.additionalInfo[0]?.hawbNumber || <span style={{ fontStyle: 'italic' }}>- Not Entered -</span>},

            ]
        },
        {
            title: 'Notification Info',
            additionalInfo: []
        }
        ]
    }

    const [expanded, setExpanded] = useState(
        shipmentPreview.map((_: any, index: any) => index === 0 || index === shipmentPreview.length - 1)
    );
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    useEffect(() => {
        setNotifyData(shipmentData.notify)
        setShipmentInfoData(shipmentData.additionalInfo)
        console.log(shipmentData.additionalInfo)
        console.log(mode, "mode")
        let mode1 = mode === 'Ocean' ? 'INTERMODAL_SHIPMENT' : 'AIR_SHIPMENT'
        if (mode1 != shipmentData.additionalInfo[0].shipmentMode) {
            navigate('/shipmentRegistrations')
        }
    }, [mode])
    const columns: TableColumn<any>[] = [
        {
            name: "NAME",
            width: '200px',
            sortable: true,

            cell: (row: any) => {
                if (row.identifier !== '' && row.identifier !== null && row.identifier !== undefined) {
                    return <span>{row.identifier}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "EMAIL",
            width: '200px',
            sortable: true,

            cell: (row: any) => {
                if (row.email !== '' && row.email !== null && row.email !== undefined) {
                    return <span>{row.email}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },


    ]
    const columns1: TableColumn<any>[] = [
        {
            name: "CARRIER",
            width: '200px',
            sortable: true,

            cell: (row: any) => {
                if (row.carrierName !== '' && row.carrierName !== null && row.carrierName !== undefined) {
                    if (mode === 'Ocean') {
                        return <span>{row.carrierName.carrierName}</span>;
                    } else {
                        return <span>{row.carrierName}</span>;
                    }

                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "CONTAINER #",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.containerNumber !== '' && row.containerNumber !== null && row.containerNumber !== undefined) {
                    return <span>{row.containerNumber}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "MBL #",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.mblNumber !== '' && row.mblNumber !== null && row.mblNumber !== undefined) {
                    return <span>{row.mblNumber}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "MAWB #",
            width: '200px',
            sortable: true,
            omit: mode == 'Ocean' ? true : false,
            cell: (row: any) => {
                if (row.awbNumber !== '' && row.awbNumber !== null && row.awbNumber !== undefined) {
                    return <span>{row.awbNumber}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "INVOICE #",
            width: '200px',
            sortable: true,

            cell: (row: any) => {
                if (row.invoiceNumber !== '' && row.invoiceNumber !== null && row.invoiceNumber !== undefined) {
                    return <span>{row.invoiceNumber}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "INCOTERMS",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.incoTerms !== '' && row.incoTerms !== null && row.incoTerms !== undefined) {
                    return <span>{row.incoTerms.incotermName}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "SHIPPER",
            width: '200px',
            sortable: true,

            cell: (row: any) => {
                if (row.shipperName !== '' && row.shipperName !== null && row.shipperName !== undefined) {
                    return <span>{row.shipperName}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "CONSIGNEE",
            width: '200px',
            sortable: true,

            cell: (row: any) => {
                if (row.consigneeName !== '' && row.consigneeName !== null && row.consigneeName !== undefined) {
                    return <span>{row.consigneeName}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
    ]
    const columns2: TableColumn<any>[] = [
        {
            name: "BOOKING #",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.bookingNumber !== '' && row.bookingNumber !== null && row.bookingNumber !== undefined) {
                    return <span>{row.bookingNumber}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "SHIPMENT TYPE",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.mode !== '' && row.mode !== null && row.mode !== undefined) {
                    return <span>{row.mode}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "HBL #",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.hblNumber !== '' && row.hblNumber !== null && row.hblNumber !== undefined) {
                    return <span>{row.hblNumber}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "SHIPMENT REFERENCE #",
            width: '250px',
            sortable: true,

            cell: (row: any) => {
                if (mode === 'Ocean') {
                    if (row.shipmentReferenceNo !== '' && row.shipmentReferenceNo !== null && row.shipmentReferenceNo !== undefined) {
                        return <span>{row.shipmentReferenceNo}</span>;
                    } else {
                        return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                    }
                } else {
                    if (row.hawbNumber !== '' && row.hawbNumber !== null && row.hawbNumber !== undefined) {
                        return <span>{row.hawbNumber}</span>
                    } else {
                        return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                    }
                }

            }
        },
        {
            name: "PROMISED ETA",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.actualETA !== '' && row.actualETA !== null && row.actualETA !== undefined) {
                    return <span>{row.actualETA}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "TOTAL PACKAGE",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.totalPack !== '' && row.totalPack !== null && row.totalPack !== undefined) {
                    return <span>{row.totalPack}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "PACKAGE UOM",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.packUOM !== '' && row.packUOM !== null && row.packUOM !== undefined) {
                    return <span>{row.packUOM}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "TOTAL WEIGHT",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.totalWeight !== '' && row.totalWeight !== null && row.totalWeight !== undefined) {
                    return <span>{row.totalWeight}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "WEIGHT UOM",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.weightUOM !== '' && row.weightUOM !== null && row.weightUOM !== undefined) {
                    return <span>{row.weightUOM}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "TOTAL VOLUME",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.totalVolume !== '' && row.totalVolume !== null && row.totalVolume !== undefined) {
                    return <span>{row.totalVolume}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "VOLUME UOM",
            width: '200px',
            sortable: true,
            omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.volumeUOM !== '' && row.volumeUOM !== null && row.volumeUOM !== undefined) {
                    return <span>{row.volumeUOM}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
    ]
    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        const newExpanded = [...expanded];
        newExpanded[panel] = isExpanded;
        setExpanded(newExpanded);
    };
    const back = () => {
        navigate('/shipmentRegistrations')

    }
    const submitPreview = () => {
        let payload: any;
        payload = JSON.parse(JSON.stringify(shipmentData));

        // Modify notify property
        if (payload.additionalInfo && payload.additionalInfo[0]) {
            payload.additionalInfo[0].notify = payload.notify;
        }
        if(mode === 'Ocean'){
            payload.additionalInfo = (payload.additionalInfo || []).map((item: any) => {
                if (item.carrierName) {
                    item.carrierName = item.carrierName.carrierName;
                }
    
                item.shipmentRegistrationType = props.tab;
    
                if (item.incoTerms) {
                    item.incoTerms = item.incoTerms.incotermName;
                }
                item.shipmentRegistrationType = 'byContainer'
                return item;
            });
        }else{
            payload.additionalInfo = (payload.additionalInfo || []).map((item: any) => {
                if (item.carrierName) {
                    item.carrierName = item.carrierName;
                }
    
                item.shipmentRegistrationType = props.tab;
    
                if (item.incoTerms) {
                    item.incoTerms = item.incoTerms.incotermName;
                }
                item.shipmentRegistrationType = 'byAWB'
                return item;
            });
        }
        
        // }

        console.log(payload.additionalInfo);
        console.log("Payload is ...", payload);
        setLoader(true)
        axios.post(Endpoints.shipment_Registration, payload, config).then((res: any) => {
            console.log(res.data, "shipment creation")
            try {
                if (res.data.status.code === 'SUCCESS') {
                    toast.success('Shipment registered successfully and is currently being processed.', {
                        autoClose: 3000,
                        onClose: () => {
                            setTimeout(() => {
                                payload = {};
                                dispatch(resetShipmentData(payload));
                                navigate('/shipmentRegistrations')

                            }, 1000);
                        },
                    });
                    setLoader(false)
                } else {
                    if (res.data.status.code === 'Error') {
                        swal('Warning', res.data.status.message, 'warning')
                    }
                }
            } catch (error: any) {
                setLoader(false)
                swal('Warning', 'The shipment you are trying to register already exists with the same combination.', 'warning')
            }

        }).catch((e: any) => {
            setLoader(false)
        })
    }
    return (
        <div className={classes.root}>
            <Button
                variant="contained"
                type="submit"
                onClick={back}
                size="small"
                sx={{
                    float: 'right', backgroundColor: "#555a64",
                    color: "#fff",
                    mb: 2
                }}
            >
                back
            </Button>
            <Grid container spacing={2} sx={{ display: 'flex', }}>
                {shipmentPreview.map((item: any, index: any) => (
                    <Grid item xs={4}>
                        {item.title === 'Shipment Info' || item.title === 'Air Info' ? (
                            <Box sx={{ border: '1px solid #ddd', }}>
                                <Typography className='ShipmentTitle' sx={{ borderBottom: '2px solid #ddd', display: 'flex', color: '#1976d2', fontWeight: 'bold' }}>{item.title.toUpperCase()}</Typography>
                                <Box className="ShipmentInfo">
                                    {item.shipmentInfo.map((item1: any, index1: any) => (
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 4, py: 0.5 }}>
                                            <Typography sx={{ color: "#333", fontSize: "14px", fontWeight: 'bold', width: '200px' }}>{item1.header}</Typography>
                                            <Typography sx={{ fontSize: "14px", wordBreak: 'break-all' }}>{item1.field}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>

                        ) : item.title === 'Additional Info' ? (
                            <Box sx={{ border: '1px solid #ddd' }}>
                                <Typography className='ShipmentTitle' sx={{ borderBottom: '2px solid #ddd', display: 'flex', color: '#1976d2', fontWeight: 'bold' }}>{item.title.toUpperCase()}</Typography>
                                <Box className="ShipmentInfo">
                                    {item.additionalInfo.map((item1: any, index1: any) => (
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 4, py: 0.5 }}>
                                            <Typography sx={{ color: "#333", fontSize: "14px", fontWeight: 'bold', width: '200px' }}>{item1.header}</Typography>
                                            <Typography sx={{ fontSize: "14px", wordBreak: 'break-all' }}>{item1.field}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ) : <Box sx={{ border: '1px solid #ddd' }}>
                            <Typography className='ShipmentTitle' sx={{ borderBottom: '2px solid #ddd', display: 'flex', color: '#1976d2', fontWeight: 'bold' }}>{item.title.toUpperCase()}</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', overflowY: 'scroll' }}>
                                <Box className="ShipmentInfo">
                                    <Typography sx={{ color: "#333", fontSize: "14px", fontWeight: 'bold' }}>Name</Typography>
                                    {shipmentData.notify.map((item1: any, index: any) => (
                                        <Typography sx={{ fontSize: "14px", wordBreak: 'break-all' }}>{item1.identifier}</Typography>
                                    ))}
                                </Box>
                                <Box className="ShipmentInfo">
                                    <Typography sx={{ color: "#333", fontSize: "14px", fontWeight: 'bold' }}>Email</Typography>
                                    {shipmentData.notify.map((item1: any, index: any) => (
                                        <Typography sx={{ fontSize: "14px", wordBreak: 'break-all' }}>{item1.email}</Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                        }

                    </Grid>
                ))}

            </Grid>
            <LoadingButton
                sx={{
                    my: 2, textAlign: 'center',

                }}
                variant="contained"
                size='small'
                type='submit'
                loading={loader}
                onClick={submitPreview}


            >
                submit
            </LoadingButton>
            <ToastContainer />
        </div>
    )
}