import { Alert, AlertTitle, Box, Paper } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useOutletContext } from 'react-router-dom';
import { Endpoints } from '../../../Enviornment/Endpoints';
import moment from 'moment';
const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#0b51b7'
        },
    },
    headCells: {
        style: {
            fontSize: '14px',
            color: 'white'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },

};
export default function DutyFreeRetailList() {
    const [dutyFreeList, setDutyFreeList] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState<any>('');
    const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
    const columns: TableColumn<any>[] = [
        {
            name: "S.NO",
            width: '100px',
            cell: (row: any, index: number) => {
                return <span>{row.serialNumber}</span>
            }
        },
        {
            name: "STORE NAME",
            sortable: true,
            width: '250px', 
            cell: (row: any) => {
                if (row.shopName !== '' && row.shopName !== null && row.shopName !== undefined) {
                    return <span>{row.shopName}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "STORE TYPE",
            width: '150px',
            sortable: true,
            // omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.type !== '' && row.type !== null && row.type !== undefined) {
                    return <span>{row.type}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "CREATED DATE",
            width: '250px',
            sortable: true,
            // omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.capturedDate !== '' && row.capturedDate !== null && row.capturedDate !== undefined) {
                    return <span>{moment(row.capturedDate).format('DD-MMM-YYYY')}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },
        {
            name: "ADDRESS",
            width: '700px',
            sortable: true,
            // omit: mode == 'Air' ? true : false,
            cell: (row: any) => {
                if (row.address !== '' && row.address !== null && row.address !== undefined) {
                    return <span>{row.address}</span>;
                } else {
                    return <span style={{ fontStyle: 'italic' }}>- NA -</span>;
                }
            }
        },


    ]
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        params: {}
    };
    const handleSearch = (event: any) => {
        setSearchTerm(event.target.value);
        console.log(event.target.value, "target value")
    };
    const handleClear = () => {
        setSearchTerm('')
    }
    // const filterData = dutyFreeList.filter((data: any) => {
    //     return data.dutyFreeList.toLowerCase().includes(searchTerm.toLowerCase())
    // });
    const getDutyFreeList = () => {
        setLoad(true)
        axios.get(Endpoints.Duty_Free_Retail_List, config).then((res: any) => {
            setLoad(false)
            if (res.data.primary.length > 0) {
                const responseDataWithSerialNumber = res.data.primary.map((data: any, index: any) => ({
                    ...data,
                    serialNumber: index + 1
                }));
                setDutyFreeList(responseDataWithSerialNumber)
            }

        })
    }
    useEffect(() => {
        getDutyFreeList()

    }, [mode])
    return (
        <div>
            <Paper elevation={3} sx={{ borderRadius: 3 }}>
                {dutyFreeList.length > 0 ? (
                    <Box sx={{ p: 0, mt: 1 }}>
                        <DataTable
                            style={{ borderRadius: 3 }}
                            columns={columns}
                            data={dutyFreeList}
                            responsive={true}
                            customStyles={customStyles}
                            theme="default"
                            pagination
                            dense
                            striped
                            highlightOnHover={true}

                        />
                    </Box>)
                    : (
                        <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                            No data found
                        </Alert>
                    )}
            </Paper></div>
    )
}
