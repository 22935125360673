import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Badge, Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tab, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ShipmentRegistrationForm from './ShipmentRegistrationForm';
import { useNavigate, useOutletContext } from 'react-router-dom';
import CarrierWiseList from './CarrierWiseList';

export default function ShipmentRegistrations() {
    const navigate = useNavigate();
    const [value, setValue] = React.useState('1');
    const [load, setLoad, fromDate, toDate, format, mode] = useOutletContext<any>();
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {

    }, [mode])
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={3}></Grid>
                <Grid item xs={9}>
                    <Box sx={{ width: '100%', typography: 'body1', backgroundColor: '#03025c' }}>
                        {mode === 'Ocean' ?
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label={mode === "Ocean" ? "By Container #" : "By MAWB# "} value="1" sx={{
                                            "&.Mui-selected": {
                                                color: '#fff !important',
                                            },
                                            "&.MuiTab-root": {
                                                // color: '#000',
                                                color: '#fff !important',
                                            },

                                        }} />
                                        <Tab label="By Booking #" value="2" sx={{
                                            "&.Mui-selected": {
                                                color: '#fff !important',
                                            },
                                            "&.MuiTab-root": {
                                                // color: '#000',
                                                color: '#fff !important',
                                            },

                                        }} />
                                        <Badge badgeContent='Genius' color="primary" sx={{
                                            "& .MuiBadge-badge": {
                                                marginTop: '18px',
                                                marginRight: '-15px'
                                            }
                                        }}>

                                        </Badge>

                                        <Tab label="By MBL #" value="3" sx={{
                                            "&.Mui-selected": {
                                                color: '#fff !important',
                                                marginLeft: '50px'
                                            },
                                            "&.MuiTab-root": {
                                                // color: '#000',
                                                marginLeft: '50px',
                                                color: '#fff !important',

                                            },

                                        }} />
                                        <Badge badgeContent='Genius' color="primary" sx={{
                                            "& .MuiBadge-badge": {
                                                marginTop: '18px',
                                                marginRight: '-15px'
                                            }
                                        }}>

                                        </Badge>
                                        <Tab label="By Vessel #" value="4" sx={{
                                            "&.Mui-selected": {
                                                color: '#fff !important',
                                                marginLeft: '50px'
                                            },
                                            "&.MuiTab-root": {
                                                // color: '#000',
                                                marginLeft: '50px',
                                                color: '#fff !important',
                                            },

                                        }} />
                                        <Badge badgeContent='Genius' color="primary" sx={{
                                            "& .MuiBadge-badge": {
                                                marginTop: '18px',
                                                marginRight: '-15px'
                                            }
                                        }}>

                                        </Badge>
                                        <Tab
                                            label="Upload"
                                            value="5"
                                            onClick={() => navigate('/shipment_bulk_upload')}
                                            sx={{ marginLeft: 'auto', color: '#fff' }} // Push the last tab to the right
                                        />
                                        <Tab
                                            label="carrier Info"
                                            value="5"
                                            onClick={() => navigate('/supported_carrier_list')}
                                            sx={{ marginLeft: 'auto', color: '#fff' }} // Push the last tab to the right
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" sx={{ padding: '8px 0px' }}>
                                    <ShipmentRegistrationForm tab='byContainer' type={mode} />
                                </TabPanel>
                                <TabPanel value="2" sx={{ padding: '8px 0px' }}>
                                    <ShipmentRegistrationForm tab='byBooking' type={mode} />
                                </TabPanel>
                                <TabPanel value="3" sx={{ padding: '8px 0px' }}>
                                    <ShipmentRegistrationForm tab='byMBL' type={mode} />
                                </TabPanel>
                                <TabPanel value="4" sx={{ padding: '8px 0px' }}>
                                    <ShipmentRegistrationForm tab='byVessel' type={mode} />
                                </TabPanel>

                            </TabContext> :
                            <Box>
                                <Box sx={{ marginLeft: 'auto', color: '#fff', float: 'right' }} >
                                    <Button
                                        variant='text'
                                        onClick={() => navigate('/shipment_bulk_upload')}
                                        sx={{ color: '#fff', mx: 2 }}
                                    >Upload</Button>
                                    <Button
                                        variant='text'
                                        onClick={() => navigate('/supported_carrier_list')}
                                        sx={{ color: '#fff', mx: 2 }}
                                    >Carrier List</Button>
                                </Box>
                                <ShipmentRegistrationForm tab='byAWB' type={mode} />
                            </Box>}
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}
